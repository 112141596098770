import React, { useEffect } from "react";

import arrow from "../../../../../assets/Vector.png";

import "./Step1.css";
import { toast } from "react-toastify";

const navigateimulator = {
  menu: "Simulador do Atendente",
  index: 6,
};

const Step1 = ({ form, saveLoading, setMenuSelected, checkList, disabled, setCheckSave, scrollSimulator }) => {

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  
  const handleMenu = (item) => {
    if (
      item.menu === "Simulador do Atendente" &&
      !checkList?.partnerDetailsFilled
    ) {
      toast.error(
        "Atenção: preencha primeiro o nome do (a) atendente, o nome fantasia do negócio e o nicho do negócio para ter acesso ao simulador do atendente!"
      );
    } else {
      setMenuSelected(item);
      if(isMobileDevice) {
        scrollSimulator();
      }
    }
  };


  const handleInput = (event) => {
    const { name, value } = event.target;
    form.setFieldValue(name, value);
    setCheckSave(false);
  };

  return (
    <div className="containerStep1">
      <div className="titlePage">Dados Básicos</div>

      <div className="containerInput">
        <div className="title">Nome do/a Atendente</div>
        <div className="subTitleInput">
          Insira o nome do atendente responsável por interações com os clientes
        </div>
        <input
          className="inputStyle"
          type="text"
          name="nameAttendant"
          onChange={handleInput}
          onBlur={form.handleBlur}
          value={form.values.nameAttendant}
          disabled={disabled}
        />
        {form.touched.nameAttendant && form.errors.nameAttendant ? (
          <div style={{ color: "red" }}>{form.errors.nameAttendant}</div>
        ) : null}
      </div>

      <div className="containerInput">
        <div className="title">Nome Fantasia do Negócio</div>
        <div className="subTitleInput">
          Digite o nome que o atendente usará para se referir ao seu negócio
        </div>
        <input
          className="inputStyle"
          type="text"
          name="companyName"
          onChange={handleInput}
          onBlur={form.handleBlur}
          value={form.values.companyName}
          disabled={disabled}
        />
        {form.touched.companyName && form.errors.companyName ? (
          <div style={{ color: "red" }}>{form.errors.companyName}</div>
        ) : null}
      </div>

      <div className="containerInput">
        <div className="title">Nicho do Negócio</div>
        <div className="subTitleInput">
          Especifique a área de atuação do seu negócio para melhor
          direcionamento
        </div>
        <input
          className="inputStyle"
          type="text"
          name="companyNiche"
          onChange={handleInput}
          onBlur={form.handleBlur}
          value={form.values.companyNiche}
          disabled={disabled}
        />
        {form.touched.companyNiche && form.errors.companyNiche ? (
          <div style={{ color: "red" }}>{form.errors.companyNiche}</div>
        ) : null}
      </div>

      {!disabled && (
        <div className="containerButtons">
          <div
            onClick={() => handleMenu(navigateimulator)}
            className={`buttonSimulator ${
              !checkList?.partnerDetailsFilled && "disabledMenus"
            }`}
          >
            Simular atendimento
          </div>

          <div onClick={form.handleSubmit} className="buttonSave">
            {saveLoading ? (
              "Carregando..."
            ) : (
              <>
                Salvar alterações
                <img src={arrow} alt="arrow" />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Step1;
