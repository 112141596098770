import React from "react";
import arrow from "../../../../../assets/Vector.png";
import { ChanelSocial } from "../ChanelSocial";

import "./Step4.css";
import { toast } from "react-toastify";
import { Checkbox, ListItemText, MenuItem } from "@mui/material";
import { NewSelectWithLabel } from "../../../../../components/NewSelectWithLabel";

const optionsArray = [
  "Agendar uma reunião",
  "Adquirir email do cliente",
  "Outro",
];

const toneArray = [
  "Respeitoso & Empático",
  "Animado & Engajador",
  "Profissional & Firme",
  "Atencioso & Calmo",
  "Encorajador & Motivador",
  "Compassivo & Compreensivo",
  "Divertido & Cativante",
  "Cortês & Gentil",
  "Solidário & Amigável",
  "Eficiente & Rápido",
  "Confiável & Preciso",
  "Assertivo & Decisivo",
  "Perspicaz & Observador",
  "Outro",
];

const preferentialLanguage = [
  "Português Brasileiro",
  "Inglês",
  "Japonês",
  "Mandarim",
  "Hindi",
  "Espanhol",
];

const navigateimulator = {
  menu: "Simulador do Atendente",
  index: 6,
};

const CheckboxObjectives = ({ label, isChecked, onChange, disabled }) => {
  return (
    <label className="checkbox-container">
      <div className="checkmarkBackground">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={onChange}
          disabled={disabled}
        />
        <span className="checkmark"></span>
      </div>
      <div>{label}</div>
      {label === "Outro" && (
        <div className="subTitleInput" style={{ margin: "0px", color: "red" }}>
          (É aqui que você dá vida para o seu atendente virtual)
        </div>
      )}
    </label>
  );
};

const Step4 = ({
  form,
  saveLoading,
  setMenuSelected,
  checkList,
  section2Ref,
  disabled,
  setCheckSave,
  scrollSimulator,
}) => {
  const toggleObjective = (event) => {
    // Obtém o valor atual dos objetivos
    const prevObjectives = form?.values?.companyObjectives || [];

    // Verifica se o objetivo já está na lista
    const isObjectiveIncluded = prevObjectives.includes(event.target.name);

    form.setFieldValue(
      "companyObjectives",
      isObjectiveIncluded
        ? prevObjectives.filter((objective) => objective !== event.target.name)
        : [...prevObjectives, event.target.name]
    );
  };

  const handleChange = (event, field) => {
    let values = event.target.value;

    // Verifica se o valorEspecial está no array.
    const especialIndex = values.indexOf("Outro");

    // Se o valorEspecial estiver no array, remova-o e adicione-o de volta ao final.
    if (especialIndex !== -1) {
      values.splice(especialIndex, 1);
      values.push("Outro");
    }

    form.setFieldValue(field, values);
  };

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  const handleMenu = (item) => {
    if (
      item.menu === "Simulador do Atendente" &&
      !checkList.partnerDetailsFilled
    ) {
      toast.error(
        "Atenção: preencha primeiro o nome do (a) atendente, o nome fantasia do negócio e o nicho do negócio para ter acesso ao simulador do atendente!"
      );
    } else {
      setMenuSelected(item);
      if (isMobileDevice) {
        scrollSimulator();
      }
    }
  };

  const handleInput = (event) => {
    const { name, value } = event.target;
    form.setFieldValue(name, value);
    setCheckSave(false);
  };

  return (
    <div className="containerStep4" ref={section2Ref}>
      <div className="titlePage">Estratégia de Atendimento</div>
      <div className="containerInputs">
        <div className="card">
          <div className="containerInput" style={{ height: "100%" }}>
            <div className="title">Canais de Comunicação</div>
            <div className="subTitleInput">
              Liste os canais e seus respectivos links para promover seu negócio
            </div>
            <ChanelSocial
              label="Canais de Comunicação"
              form={form}
              helperText="Liste os canais e seus respectivos links para promover seu negócio e interagir com os clientes"
              variant="outlined"
              disabled={disabled}
            />
          </div>

          <div className="containerInput">
            <div className="title">Tom e Voz</div>
            <div className="subTitleInput">
              Escolha o estilo e a atitude da comunicação que refletem a
              personalidade do negócio
            </div>
            {/* <select
          className="inputStyle"
          type="text"
          name="toneVoice"
          onChange={(event) => handleChange(event, "toneVoice")}
         onBlur={form.handleBlur}
          value={form.values.toneVoice}
        >
          {toneArray.map((item, index) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))}
        </select> */}

            <NewSelectWithLabel
              variant="outlined"
              form={form}
              value={"toneVoice"}
              renderValue={(selected) => selected.join(", ")}
              onChange={(event) => handleChange(event, "toneVoice")}
              multiple
              disabled={disabled}
              className="inpurt"
            >
              {toneArray.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    <Checkbox
                      checked={form.values.toneVoice.indexOf(item) > -1}
                      style={{ color: "#102649" }}
                    />
                    <ListItemText
                      className="textTOne"
                      style={{ color: "#102649", fontWeight: "400" }}
                    >
                      {item}
                    </ListItemText>
                  </MenuItem>
                );
              })}
            </NewSelectWithLabel>
          </div>

          {/* <div className="containerInput">
            <div className="title">Diferenciais Chave</div>
            <div className="subTitleInput">
              Destaque os principais aspectos que tornam seu negócio único no
              mercado
            </div>
            <textarea
              className="inputStyle"
              type="text"
              name="keyDifferentials"
              onChange={handleInput}
             onBlur={form.handleBlur}
              value={form.values.keyDifferentials}
              rows="4"
              style={{ height: "100%" }}
            />
          </div> */}
        </div>

        <div className="card">
          <div className="containerInput">
            <div className="title">Objetivos do Atendente</div>
            <div className="subTitleInput">
              Defina as principais intenções do atendente durante a conversa com
              o cliente
            </div>

            <div className="checkbox-group">
              {optionsArray.map((item, index) => (
                <CheckboxObjectives
                  key={index}
                  label={item}
                  isChecked={form?.values?.companyObjectives?.includes(item)}
                  onChange={(e) =>
                    toggleObjective({
                      target: { name: item },
                    })
                  }
                  disabled={disabled}
                />
              ))}
            </div>

            <textarea
              className="inputStyle"
              type="text"
              name="companyObjectivesExtra"
              onChange={handleInput}
              onBlur={form.handleBlur}
              value={form.values.companyObjectivesExtra}
              rows="4"
              style={{ height: "100%" }}
              disabled={
                !form?.values?.companyObjectives?.includes("Outro") || disabled
              }
            />
          </div>
          <div className="containerInput">
            <div className="title">Idioma de Resposta</div>
            <div className="subTitleInput">
              Idioma que o atendente usará para responder o cliente
            </div>
            <select
              className="inputStyle"
              type="text"
              name="preferentialLanguage"
              onChange={handleInput}
              onBlur={form.handleBlur}
              value={form.values.preferentialLanguage}
              disabled={disabled}
            >
              {preferentialLanguage.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {!disabled && (
        <div className="containerButtons">
          <div
            onClick={() => handleMenu(navigateimulator)}
            className={`buttonSimulator ${
              !checkList?.partnerDetailsFilled && "disabledMenus"
            }`}
          >
            Simular atendimento
          </div>

          <div onClick={form.handleSubmit} className="buttonSave">
            {saveLoading ? (
              "Carregando..."
            ) : (
              <>
                Salvar alterações
                <img src={arrow} alt="arrow" />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Step4;
