import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useContext, useEffect } from "react";
import logo from "../../assets/altLogo.svg";

import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { AuthContext } from "../../providers/AuthProvider";
import { InputField } from "../../components/InputField";
import validateCPF from "../../utils/validateCPF";
import validateCNPJ from "../../utils/validateCNPJ";

export const SignUpSales = () => {
  const { signUpSales } = useContext(AuthContext);
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  const form = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      password: "",
      passwordConfirmation: "",
      cpf: "",
      cnpj: "",
      documentType: "",
      terms: false,
    },

    validationSchema: yup.object({
      name: yup.string().required("Nome é obrigatório!"),
      phone: yup
        .string()
        .min(14, "Telefone inválido!")
        .required("Telefone de contato é obrigatório!"),

      cpf: yup.string().when("documentType", {
        is: "cpf",
        then: () =>
          yup
            .string()
            .required("Número do documento é obrigatório!")
            .test({
              name: "isValid",
              exclusive: false,
              params: {},
              message: "CPF inválido",
              test: function (value) {
                return validateCPF(value || "");
              },
            }),
      }),

      cnpj: yup.string().when("documentType", {
        is: "cnpj",
        then: () =>
          yup
            .string()
            .required("Número do documento é obrigatório!")
            .test({
              name: "isValid",
              exclusive: false,
              params: {},
              message: "CNPJ inválido",
              test: function (value) {
                return validateCNPJ(value || "");
              },
            }),
      }),

      documentType: yup
        .string()
        .required("Escolher um tipo de documento é obrigatório!"),
      email: yup
        .string()
        .email("Formato de email inválido.")
        .required("Email é obrigatório!"),
      password: yup
        .string()
        .min(8, "Senha deve conter no mínimo 8 caracteres.")
        .required("Senha é obrigatória!"),

      passwordConfirmation: yup
        .string()
        .required("Confirmação da senha é obrigatoria!")
        .oneOf([yup.ref("password"), ""], "As senhas devem ser iguais."),
    }),

    onSubmit: (values) => handleContinue(values),
  });

  const handleContinue = async (values) => {
    try {
      setLoading(true);

      const response = await signUpSales({ values });

      if (response === "success") {
        navigate("/vendas/dashboard");
      }

      setLoading(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    //console.log(form.values);
  }, [form.values]);

  return (
    <Box
      style={{
        display: "flex",
        backgroundColor: "#f1fcf4",
        width: "100%",
        minHeight: "100vh",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img
        src={logo}
        alt={""}
        style={{
          width: 600,
          marginBottom: "-50px",
          paddingRight: "15px",
        }}
      />

      <div
        style={{
          flexDirection: "column",
          display: "flex",
          marginBottom: "2%",
          width: "400px",
        }}
      >
        <InputField validate label="Nome Completo" value={"name"} form={form} />

        <InputField
          validate
          label="Telefone de Contato"
          value={"phone"}
          form={form}
          mask={
            form.values.phone.replace(/\s/g, "").length > 13
              ? "(99) 9 9999-9999"
              : "(99) 9999-99999"
          }
          maskChar={""}
        />
        {/* <InputField
          validate
          label="Telefone do Assistente Virtual"
          value={"phoneAttendant"}
          form={form}
          mask={
            form.values.phoneAttendant.replace(/\s/g, "").length > 13
              ? "(99) 9 9999-9999"
              : "(99) 9999-99999"
          }
          maskChar={""}
        /> */}

        <div
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          <RadioGroup
            row
            value={form.values.documentType}
            onClick={(e) => {
              if (e.target.value)
                form.setFieldValue("documentType", e.target.value);
            }}
          >
            <FormControlLabel value="cpf" control={<Radio />} label="CPF" />
            <FormControlLabel value="cnpj" control={<Radio />} label="CNPJ" />
          </RadioGroup>

          {form.errors.documentType && form.touched.documentType && (
            <Typography
              style={{ marginBottom: "5px", marginTop: "5px" }}
              color={"red"}
            >
              {form.errors.documentType}
            </Typography>
          )}

          <InputField
            validate
            label="Número do Documento"
            value={
              form.values.documentType === "cpf"
                ? "cpf"
                : form.values.documentType === "cnpj"
                ? "cnpj"
                : null
            }
            form={form}
            disabled={form.values.documentType === ""}
            mask={
              form.values.documentType === "cpf"
                ? "999.999.999-99"
                : form.values.documentType === "cnpj"
                ? "99.999.999/9999-99"
                : ""
            }
            maskChar={""}
          />
        </div>
        <InputField validate label="Email" value={"email"} form={form} />
        <InputField
          validate
          label="Senha"
          value={"password"}
          form={form}
          type={"password"}
        />
        <InputField
          validate
          label="Confirmação de Senha"
          value={"passwordConfirmation"}
          form={form}
          type={"password"}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Checkbox
            checked={form.values.terms}
            onChange={() => form.setFieldValue("terms", !form.values.terms)}
            style={{
              padding: 0,
              margin: "5px",
              marginLeft: "0px",
              marginRight: "10px",
            }}
          />
          <Typography style={{ textAlign: "center" }}>
            Concordo com os{" "}
            <a
              href="https://sacgpt.com.br/termos"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#539d65", textDecoration: "underline" }}
            >
              termos de uso
            </a>
          </Typography>
        </div>

        <Button
          onClick={form.handleSubmit}
          variant="contained"
          style={{ marginTop: 15 }}
          disabled={!form.values.terms}
        >
          <Typography color={"#fff"}>
            {loading ? "Carregando..." : "Cadastrar"}
          </Typography>
        </Button>
      </div>
    </Box>
  );
};
