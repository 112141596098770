import React, { useContext } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { AuthContext } from "../../providers/AuthProvider";
import { Button, Typography } from "@mui/material";

import image1 from "../../assets/fuundo_ondas (1).png";
import image2 from "../../assets/fuundo_ondas.png";
import logo from "../../assets/Frame 1.png";
import arrow from "../../assets/Vector.png";
import iconRegister from "../../assets/NotePencil.png";

import "./SignUp.css";

const SignUp = () => {
  const navigate = useNavigate();
  const { userType } = useParams();
  const { signIn, signInAdmin, signInSales, signUp } = useContext(AuthContext);
  const [loading, setLoading] = React.useState(false);

  const handleType = () => {
    switch (userType) {
      case "admin":
        return "Administrador";
      case "vendas":
        return "Afiliado";
      default:
        return "Parceiro";
    }
  };

  const type = handleType();

  const handleInv = () => {
    switch (userType) {
      case "admin":
        return ["Parceiro", "Afiliado"];
      case "vendas":
        return ["Parceiro", "Administrador"];
      default:
        return ["Afiliado", "Administrador"];
    }
  };

  const invType = handleInv();

  const form = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      passwordConfirmation: "",
    },

    validationSchema: yup.object({
      name: yup.string().required("O campo nome é obrigatório!"),
      email: yup
        .string()
        .email("Formato de email inválido.")
        .required("O campo e-mail é obrigatório!"),
      password: yup
        .string()
        .min(8, "Senha deve conter no mínimo 8 caracteres.")
        .required("O campo senha é obrigatório!"),
      passwordConfirmation: yup
        .string()
        .oneOf([yup.ref("password"), null], "As senhas devem corresponder.")
        .required("O campo de confirmação de senha é obrigatório!!"),
    }),

    onSubmit: (values) => handleContinue(values),
  });

  const handleContinue = async (values) => {
    // const newValues = {
    //   ...values,
    //   afilliated: values.afilliated.name,
    // };

    try {
      setLoading(true);

      const response = await signUp({ values });

      if (response === "success") {
        navigate("/dashboard");
      }

      setLoading(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="containerRegister">
      <header className="headerRegister">
        <img src={logo} alt="logo" />
      </header>

      <img src={image1} className="image1" alt="image1" />
      <img src={image2} className="image2" alt="image2" />

      <div className="containerCenterRegister">
        <div className="boxRegister">
          <div className="headerBoxRegister">
            <div className="text">
              <img src={iconRegister} alt="icon register" />
              CADASTRE-SE
            </div>
            <div className="subText">
              Para criar sua conta é simples, basta preencher os campos abaixo:
            </div>
          </div>
          <form
            onSubmit={form.handleSubmit}
            style={{ display: "flex", flexDirection: "column", gap: '10px' }}
          >
            <div className="labelInputRegister">Nome</div>
            <input
              className="inputRegister"
              type="text"
              onChange={(e) => {
                form.setFieldValue("name", e.target.value);
              }}
              onBlur={() => {
                form.setFieldTouched("name", true);
              }}
              autoComplete="new-password"
            />

            {form.touched.name && form.errors.name && (
              <Typography
                style={{ fontSize: 14, alignSelf: "flex-end", marginTop: 5 }}
                color={"darkred"}
              >
                {form.errors.name}
              </Typography>
            )}

            <div className="labelInputRegister">E-mail</div>
            <input
              className="inputRegister"
              type="email"
              onChange={(e) => {
                form.setFieldValue("email", e.target.value);
              }}
              onBlur={() => {
                form.setFieldTouched("email", true);
              }}
              autoComplete="new-password"
            />

            {form.touched.email && form.errors.email && (
              <Typography
                style={{ fontSize: 14, alignSelf: "flex-end", marginTop: 5 }}
                color={"darkred"}
              >
                {form.errors.email}
              </Typography>
            )}

            <div className="labelInputRegister" style={{ marginTop: 15 }}>
              Senha
            </div>
            <input
              className="inputRegister"
              type="password"
              onChange={(e) => {
                form.setFieldValue("password", e.target.value);
              }}
              onBlur={() => {
                form.setFieldTouched("password", true);
              }}
              autoComplete="new-password"
            />

            {form.touched.password && form.errors.password && (
              <Typography
                style={{ fontSize: 14, alignSelf: "flex-end", marginTop: 5 }}
                color={"darkred"}
              >
                {form.errors.password}
              </Typography>
            )}

            <div className="labelInputRegister" style={{ marginTop: 15 }}>
              Confirme sua Senha
            </div>
            <input
              className="inputRegister"
              type="password"
              onChange={(e) => {
                form.setFieldValue("passwordConfirmation", e.target.value);
              }}
              onBlur={() => {
                form.setFieldTouched("passwordConfirmation", true);
              }}
              autoComplete="new-password"
            />

            {form.touched.passwordConfirmation && form.errors.passwordConfirmation && (
              <Typography
                style={{ fontSize: 14, alignSelf: "flex-end", marginTop: 5 }}
                color={"darkred"}
              >
                {form.errors.passwordConfirmation}
              </Typography>
            )}

            <button
              type="submit"
              className="buttonRegister"
              style={{ cursor: "pointer" }}
            >
              {loading ? (
                "Carregando..."
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "15px",
                  }}
                >
                  Cadastrar
                  <img src={arrow} alt="arrow" />
                </div>
              )}
            </button>
          </form>
        </div>
      </div>

      <footer className="footerRegister">
        sacgpt.com.br © 2024 - Todos os Direitos Reservados
      </footer>
    </div>
  );
};

export default SignUp;
