import { useContext } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes as RRDRoutes,
} from "react-router-dom";

import { AuthContext } from "../providers/AuthProvider";
// import { Login } from "../screens/Login";
import Login from "../screens/Login/Login";
import { ClientList } from "../screens/PartnerScreens/ClientList";
import Clients from "../screens/PartnerScreens/ClientList/Clients";
import { Settings } from "../screens/PartnerScreens/Settings";
import Attendant from "../screens/PartnerScreens/Settings/Attendant";
import ChatHistory from "../screens/PartnerScreens/ChatHistory/ChatHistory";
import PartnerList from "../screens/AdminScreens/PartnerList/PartnerList";
import PartnerClientsList from "../screens/AdminScreens/PartnerClientsList/PartnerClientsList";
import { Finance } from "../screens/AdminScreens/Finance";
// import { FinancePartner } from "../screens/PartnerScreens/Finance";
import FinancePartner from "../screens/PartnerScreens/Finance/FinancePartner";
import NewCreditCard from "../screens/PartnerScreens/Finance/NewCreditCard";
// import { SignUp } from "../screens/SignUp";
import SignUp from "../screens/SignUp/SignUp";
import { Subscription } from "../screens/PartnerScreens/Subscription";
import Support from "../screens/PartnerScreens/Support/Support";
// import { Checkout } from "../screens/PartnerScreens/Checkout";
import Checkout from "../screens/PartnerScreens/Checkout/Checkout";
import { RecoveryRequest } from "../screens/Recovery/RecoveryRequest";
import { RecoveryNewPassword } from "../screens/Recovery/RecoveryNewPassword";
import { devEnv } from "../utils/api";
import { SettingsSteps } from "../screens/PartnerScreens/SettingsSteps";
// import { Dashboard } from "../screens/PartnerScreens/Dashboard";
import Dashboard from "../screens/PartnerScreens/Dashboard/Dashboard";
import { Account } from "../screens/PartnerScreens/Account";
import Profile from "../screens/PartnerScreens/Account/Profile";
// import { Agendamento } from "../screens/PartnerScreens/Agendamento";
import Agendamento from '../screens/PartnerScreens/Agendamento/Agendamento';
// import { Tokens } from "../screens/PartnerScreens/Tokens";
import Tokens from "../screens/PartnerScreens/Tokens/Tokens";
import { SignUpSales } from "../screens/SignUpSales";
import { AccountSales } from "../screens/SalesScreens/AccountSales";
import { CRM } from "../screens/SalesScreens/CRM";
import AttendantSales from "../screens/SalesScreens/BotTrial/Attendant";
import { BotTrial } from "../screens/SalesScreens/BotTrial";
import { DashboardSales } from "../screens/SalesScreens/DashboardSales";
import { ClientListSales } from "../screens/SalesScreens/ClientListSales";
import SalesFinance from "../screens/SalesScreens/SalesFinance";

//escolher novo plano
import Step1 from "../screens/PartnerScreens/Finance/Step1/Step1";
import Step2 from "../screens/PartnerScreens/Finance/Step2/Step2";
import Step3 from "../screens/PartnerScreens/Finance/Step3/Step3";
import NewPlanProvider from "../screens/PartnerScreens/Finance/Context/NewPlanProvider";
import SalesNewCreditCard from "../screens/SalesScreens/SalesFinance/NewCreditCard";
import SalesTokens from "../screens/SalesScreens/Tokens/Tokens";
import SalesCheckout from "../screens/SalesScreens/Checkout/Checkout";

export const Routes = () => {
  if (
    window.location.protocol === "http:" &&
    window.location.hostname !== "localhost"
  ) {
    window.location.href = window.location.href.replace("http:", "https:");
    return null;
  }

  return (
    <RRDRoutes>
      {/* SALES ROUTES */}
      <Route
        path="/vendas/conta"
        element={
          <SalesRoute>
            <AccountSales />
          </SalesRoute>
        }
      />
      <Route
        path="/vendas/dashboard"
        element={
          <SalesRoute>
            <DashboardSales />
          </SalesRoute>
        }
      />
      <Route
        path="/vendas/crm"
        element={
          <SalesRoute>
            <CRM />
          </SalesRoute>
        }
      />
      <Route
        path="/vendas/demo"
        element={
          <SalesRoute>
            <AttendantSales />
          </SalesRoute>
        }
      />
      <Route
        path="/vendas/clientes"
        element={
          <SalesRoute>
            <ClientListSales />
          </SalesRoute>
        }
      />
      <Route
        path="/vendas/financeiro"
        element={
          <SalesRoute>
            <SalesFinance />
          </SalesRoute>
        }
      />

      <Route
        path="/vendas/financeiro/cartao-de-credito"
        element={
          <SalesRoute>
            <SalesNewCreditCard />
          </SalesRoute>
        }
      />

      <Route
        path="/vendas/tokens"
        element={
          <SalesRoute>
            <SalesTokens />
          </SalesRoute>
        }
      />

      <Route
        path="/vendas/carrinho"
        element={
          <SalesRoute>
            <SalesCheckout />
          </SalesRoute>
        }
      />

      {/* PARTNER ROUTES */}

      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />

      <Route
        path="/clientes"
        element={
          <PrivateRoute>
            <Clients />
          </PrivateRoute>
        }
      />

      <Route
        path="/atendente"
        element={
          <PrivateRoute>
            <Attendant />
          </PrivateRoute>
        }
      />

      {/* <Route
        path="/atendente/passoapasso"
        element={
          <PrivateRoute>
            <SettingsSteps />
          </PrivateRoute>
        }
      />
      <Route
        path="/atendente/:hash"
        element={
          <PrivateRoute>
            <Settings />
          </PrivateRoute>
        }
      /> */}

      <Route
        path="/conta"
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />

      <Route
        path="/agendamento"
        element={
          <PrivateRoute>
            <Agendamento />
          </PrivateRoute>
        }
      />

      <Route
        path="/suporte"
        element={
          <PrivateRoute>
            <Support />
          </PrivateRoute>
        }
      />

      <Route
        path="/chat/:id"
        element={
          <PrivateRoute>
            <ChatHistory />
          </PrivateRoute>
        }
      />

      <Route
        path="/financeiro"
        element={
          <PrivateRoute>
            <FinancePartner />
          </PrivateRoute>
        }
      />

      <Route
        path="/financeiro/cartao-de-credito"
        element={
          <PrivateRoute>
            <NewCreditCard />
          </PrivateRoute>
        }
      />

      <Route
        path="/financeiro/step1"
        element={
          <PrivateRoute>
            <Step1 />
          </PrivateRoute>
        }
      />

      <Route
        path="/financeiro/step2"
        element={
          <PrivateRoute>
            <Step2 />
          </PrivateRoute>
        }
      />

      <Route
        path="/financeiro/step3"
        element={
          <PrivateRoute>
            <Step3 />
          </PrivateRoute>
        }
      />

      <Route
        path="/planos"
        element={
          <PrivateRoute>
            <Subscription />
          </PrivateRoute>
        }
      />
      <Route
        path="/tokens"
        element={
          <PrivateRoute>
            <Tokens />
          </PrivateRoute>
        }
      />
      <Route
        path="/planos/:hash"
        element={
          <PrivateRoute>
            <Subscription />
          </PrivateRoute>
        }
      />
      <Route
        path="/carrinho"
        element={
          <PrivateRoute>
            <Checkout />
          </PrivateRoute>
        }
      />
      {/* ADMIN ROUTES */}
      <Route
        path="/admin/parceiros"
        element={
          <AdminRoute>
            <PartnerList />
          </AdminRoute>
        }
      />
      <Route
        path="/admin/parceiros/:id"
        element={
          <AdminRoute>
            <PartnerClientsList />
          </AdminRoute>
        }
      />
      <Route
        path="/admin/financeiro"
        element={
          <AdminRoute>
            <Finance />
          </AdminRoute>
        }
      />
      {/* PUBLIC ROUTES */}
      <Route
        path="/:userType"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/cadastro"
        element={
          <PublicRoute>
            <SignUp />
          </PublicRoute>
        }
      />

      <Route
        path="/cadastro/:hash"
        element={
          <PublicRoute>
            <SignUp />
          </PublicRoute>
        }
      />

      <Route
        path="/vendas/cadastro"
        element={
          <PublicRoute>
            <SignUpSales />
          </PublicRoute>
        }
      />
      <Route
        path="/recuperacao"
        element={
          <PublicRoute>
            <RecoveryRequest />
          </PublicRoute>
        }
      />
      <Route
        path="/recuperacao/:hash"
        element={
          <PublicRoute>
            <RecoveryNewPassword />
          </PublicRoute>
        }
      />
      <Route path="*" element={<h1>404 Not found</h1>} />
    </RRDRoutes>
  );
};

function PublicRoute({ children }) {
  const { user } = useContext(AuthContext);

  if (user) {
    if (user.userType === "admin") return <Navigate to="/admin/parceiros" />;
    else if (user.userType === "partner") return <Navigate to="/dashboard" />;
    else if (user.userType === "sales")
      return <Navigate to="/vendas/dashboard" />;
  } else {
    return children;
  }
}

// function PrivateRoute({ children }) {
//   const { user } = useContext(AuthContext);

//   if (!user || user.userType !== "partner") {
//     return <Navigate to="/" />;
//   } else {
//     if (user?.active || devEnv) {
//       return children;
//     } else {
//       if (
//         window?.location?.pathname.includes("/planos") ||
//         window?.location?.pathname.includes("/carrinho")
//       ) {
//         return children;
//       } else {
//         return <Navigate to="/planos" />;
//       }
//     }
//   }
// }

function PrivateRoute({ children }) {
  const { user } = useContext(AuthContext);

  if (!user || user.userType !== "partner") {
    return <Navigate to="/" />;
  } else {
    return <NewPlanProvider>{children}</NewPlanProvider>;
  }
}

function AdminRoute({ children }) {
  const { user } = useContext(AuthContext);

  if (!user || user.userType !== "admin") {
    return <Navigate to="/" />;
  } else {
    return children;
  }
}

function SalesRoute({ children }) {
  const { user } = useContext(AuthContext);

  if (!user || user.userType !== "sales") {
    return <Navigate to="/" />;
  } else {
    return children;
  }
}
