import {
  AttachMoney,
  Home,
  Person,
  Settings,
  Store,
  EventAvailable,
  ContactMail,
  AppSettingsAlt,
} from "@mui/icons-material";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useContext } from "react";
import { AuthContext } from "../../providers/AuthProvider";
import { SidebarLink } from "./styles";
import { devEnv } from "../../utils/api";
import { useLocation } from "react-router-dom";

import wallet from "../../assets/Wallet.png";
import userP from "../../assets/User.png";
import users from "../../assets/Users.png";
import headset from "../../assets/Headset.png";
import diadmonds from "../../assets/DiamondsFour.png";

import "./Sidebar.css";

export const MainListItems = () => {
  const { user } = useContext(AuthContext);
  const { pathname } = useLocation();

  const color = "#77E191";
  const fontSize = 20;

  const ListedItem = ({ icon, route, title }) => {
    return (
      <SidebarLink to={route}>
        <div
          className={`containerMenuLink ${
            pathname.includes(route) && "selectedMenu"
          }`}
        >
          {/* <AttachMoney className="imgMenu" sx={{ color: color }} /> */}
          {icon}
          <div className="textMenu">{title}</div>
        </div>
      </SidebarLink>
    );
  };

  if (user.userType === "admin") {
    return (
      <div>
        <ListedItem
          route={"/admin/parceiros"}
          title={"Parceiros"}
          icon={<Store className="imgMenu" sx={{ color: color }} />}
        />

        <ListedItem
          route={"/admin/financeiro"}
          title={"Financeiro"}
          icon={<AttachMoney className="imgMenu" sx={{ color: color }} />}
        />
      </div>
    );
  } else if (user.userType === "partner")
    return (
      <div>
        <SidebarLink to={"/dashboard"}>
          <div
            className={`containerMenuLink ${
              pathname === "/dashboard" && "selectedMenu"
            }`}
          >
            <img src={diadmonds} alt="Dashboard" className="imgMenu" />
            <div className="textMenu">Dashboard</div>
          </div>
        </SidebarLink>

        {user.profileFilled  && user.partnerDetailsFilled && (
          <SidebarLink to={"/clientes"}>
            <div
              className={`containerMenuLink ${
                pathname === "/clientes" && "selectedMenu"
              }`}
            >
              <img src={users} alt="clients" className="imgMenu" />
              <div className="textMenu">Clientes</div>
            </div>
          </SidebarLink>
        )}

        <SidebarLink to={"/atendente"}>
          <div
            className={`containerMenuLink ${
              pathname === "/atendente" && "selectedMenu"
            }`}
          >
            <img src={headset} alt="Atendente" className="imgMenu" />
            <div className="textMenu">Atendente</div>
          </div>
        </SidebarLink>

        <div className="lineMenu"></div>

        <SidebarLink to={"/conta"}>
          <div
            className={`containerMenuLink ${
              pathname === "/conta" && "selectedMenu"
            }`}
          >
            <img src={userP} alt="Conta" className="imgMenu" />
            <div className="textMenu">Perfil</div>
          </div>
        </SidebarLink>

        <SidebarLink to={"/financeiro"}>
          <div
            className={`containerMenuLink ${
              pathname.includes("/financeiro") && "selectedMenu"
            }`}
          >
            <img src={wallet} alt="Financeiro" className="imgMenu" />
            <div className="textMenu">Financeiro</div>
          </div>
        </SidebarLink>

        <SidebarLink to={"/agendamento"}>
          <div
            className={`containerMenuLink ${
              pathname === "/agendamento" && "selectedMenu"
            }`}
          >
            <EventAvailable sx={{ color: color }} className="imgMenu" />

            <div className="textMenu">Agendamento</div>
          </div>
        </SidebarLink>
      </div>
    );
  else if (user.userType === "sales")
    return (
      <div>
        <ListedItem
          route={"/vendas/dashboard"}
          title={"Dashboard"}
          icon={<Home className="imgMenu" sx={{ color: color }} />}
        />

        <ListedItem
          route={"/vendas/crm"}
          title={"CRM"}
          icon={<ContactMail className="imgMenu" sx={{ color: color }} />}
        />

        <ListedItem
          route={"/vendas/demo"}
          title={"Demonstração"}
          icon={<AppSettingsAlt className="imgMenu" sx={{ color: color }} />}
        />

        <ListedItem
          route={"/vendas/clientes"}
          title={"Clientes"}
          icon={<Person className="imgMenu" sx={{ color: color }} />}
        />

        <ListedItem
          route={"/vendas/financeiro"}
          title={"Financeiro"}
          icon={<AttachMoney className="imgMenu" sx={{ color: color }} />}
        />

        <ListedItem
          route={"/vendas/conta"}
          title={"Conta"}
          icon={<Settings className="imgMenu" sx={{ color: color }} />}
        />
      </div>
    );
};
