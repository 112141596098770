import React, { useState, useContext, useEffect } from "react";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout/index";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../providers/AuthProvider";
import LeadsGraph from "./components/LeadsGraph";
import LeadsCard from "./components/LeadsCard";
import CheckList from "./components/CheckList";
import { Loader } from "../../../components/Loader";
import api from "../../../utils/api";

import iconMensage from "../../../assets/Vector (5).png";
import iconUsers from "../../../assets/Vector (4).png";
import iconWarning from "../../../assets/Warning.png";
import arrow from "../../../assets/Vector.png";

import "./Dashboard.css";

const Dashboard = () => {
  const navigate = useNavigate();
  const { user, checkList, checkLoading } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState({});
  const [clientScores, setClientScores] = useState([]);
  const [loadingClientScores, setLoadingClientScores] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(moment().format("M"));

  const getFirstName = (fullName) => {
    if (!fullName) return ""; // Verifica se a string é vazia ou null
    return fullName.split(" ")[0];
  };

  const getMetrics = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/partners/partner_reports`);

      if (data) {
        setReports(data.data.reports);

        console.log(data);
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar os dados.");
    } finally {
      setLoading(false);
    }
  };

  const fetchClientScores = async (month) => {
    setLoadingClientScores(true);
    try {
      const { data } = await api.get(`/partners/partner_clients`, {
        params: {
          month: month,
        },
      });

      const newData = data.data.map((value) => ({
        client: {
          data: {
            id: value.id,
            type: value.type,
            attributes: { ...value.attributes },
          },
        },
      }));

      setClientScores(newData);
      setLoadingClientScores(false);
    } catch (error) {
      console.error(error);
      setLoadingClientScores(false);
    }
  };

  useEffect(() => {
    fetchClientScores(selectedMonth);
    getMetrics();
  }, [selectedMonth]);

  return (
    <AuthorizedLayout>
      {checkList.profileFilled &&
      checkList.activePlan &&
      checkList.partnerDetailsFilled ? (
        loading && checkLoading ? (
          <Loader size={150} />
        ) : (
          <div className="containerDashboard">
            <div className="containerLeft">
              <div className="containerHeader">
                <span className="title">{`Olá novamente, ${getFirstName(
                  user.name
                )}!  👋`}</span>
                <span className="text">
                  Aqui está um resumo da sua assistente virtual:
                </span>
              </div>

              {!user?.connectedWithGoogle && (
                <div className="containerAlertGoogle">
                  <div className="containerText">
                    <img src={iconWarning} alt="mensagens" />
                    <span className="text">
                      <strong>Atenção:</strong> O Calendário Google não está
                      vinculado.
                    </span>
                  </div>
                  <div
                    className="button"
                    onClick={() =>
                      navigate("/atendente", {
                        state: {
                          value: {
                            menu: "Vincular Contas",
                            index: 8,
                          },
                        },
                      })
                    }
                  >
                    Vincular
                    <img src={arrow} alt="arrow" />
                  </div>
                </div>
              )}

              <LeadsGraph />

              <div className="containerInfosLeadsSends">
                <div className="containerInfo">
                  <div className="textImg">
                    <img src={iconUsers} alt="users" />
                    {reports?.overview?.leadCount || 0}
                  </div>
                  <div>Total de Leads gerados pelo atendente virtual</div>
                </div>

                <div className="containerInfo">
                  <div className="textImg">
                    <img src={iconMensage} alt="mensagens" />
                    {reports?.attendantPerformance?.answersCount || 0}
                  </div>
                  <div>Mensagens enviadas pelo seu atendente</div>
                </div>
              </div>

              <div className="containerTokens">
                <div className="containerHeaderTokens">
                  <div>Tokens consumidos do seu plano mensal:</div>
                  <div>
                    <strong>
                      {(
                        reports?.usageStatistics?.montlyTokensConsumed || 0
                      ).toLocaleString()}
                    </strong>
                    /
                    {(
                      (reports?.usageStatistics?.montlyTokensConsumed || 0) +
                      (reports?.usageStatistics?.monthlyTokensLeft || 0)
                    ).toLocaleString()}
                  </div>
                </div>

                <div className="bar">
                  <div className="fill">
                    <span
                      className="leads"
                      style={{
                        width: `${(
                          ((reports?.usageStatistics?.montlyTokensConsumed ||
                            0) /
                            ((reports?.usageStatistics?.montlyTokensConsumed ||
                              0) +
                              (reports?.usageStatistics?.monthlyTokensLeft ||
                                0))) *
                          100
                        ).toFixed(2)}%`,
                      }}
                    ></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="line"></div>

            <div className="containerRigh">
              <div className="containerHeader">
                <span className="title">Leads mais promissores</span>
                <span className="text">
                  Veja os leads com a maior pontuação de interesse:
                </span>
              </div>

              <LeadsCard reports={reports} />
            </div>
          </div>
        )
      ) : (
        <CheckList />
      )}
    </AuthorizedLayout>
  );
};

export default Dashboard;
