import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  ListItemText,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";

import { InputWithLabel } from "../../../components/InputWithLabel";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import api, { googleAuth } from "../../../utils/api";
import { AuthContext } from "../../../providers/AuthProvider";
import { Loader } from "../../../components/Loader";
import ReactInputMask from "react-input-mask";
import * as yup from "yup";
import validateCPF from "../../../utils/validateCPF";
import validateCNPJ from "../../../utils/validateCNPJ";

import { useGoogleLogin } from "@react-oauth/google";
import unmask from "../../../utils/unmask";
import { useParams } from "react-router-dom";
import { SelectWithLabel } from "../../../components/SelectWithLabel";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/keyboard";
import "./modalSwiperStyling.css";
import logo from "../../../assets/407b23225039d193f5e539e2de0900f2.svg";
import parsePhoneAdmin from "../../../utils/parsePhoneAdmin";

export const AccountSales = () => {
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const { user, updateAffiliated, signOut } = useContext(AuthContext);

  const [tempPhone, setTempPhone] = useState(user.contactNumber);
  const [modalPassword, setModalPassword] = useState(false);
  const [section, setSection] = useState("basic");

  const form = useFormik({
    initialValues: {
      name: user.name,
      phone: parsePhoneAdmin(user.contactNumber),
      email: user.email,
      cpf: user.document?.length
        ? user.document?.length === 11
          ? user.document
          : ""
        : "",
      cnpj: user.document?.length
        ? user.document?.length === 11
          ? ""
          : user.document
        : "",
      documentType: user.document?.length
        ? user.document?.length === 11
          ? "cpf"
          : "cnpj"
        : "",

      bankCode: user.paymentAccount?.bankCode || "",
      agNumber: user.paymentAccount?.agNumber || "",
      agDv: user.paymentAccount?.agDv || "",
      accNumber: user.paymentAccount?.accNumber || "",
      accDv: user.paymentAccount?.accNumber || "",
      accountType: user.paymentAccount?.accountType || "",
    },

    validationSchema: yup.object({
      name: yup.string().required("Nome é obrigatório!"),
      phone: yup
        .string()
        .min(14, "Telefone inválido!")
        .required("Telefone de contato é obrigatório!"),

      cpf: yup.string().when("documentType", {
        is: "cpf",
        then: () =>
          yup
            .string()
            .required("Número do documento é obrigatório!")
            .test({
              name: "isValid",
              exclusive: false,
              params: {},
              message: "CPF inválido",
              test: function (value) {
                return validateCPF(value || "");
              },
            }),
      }),

      cnpj: yup.string().when("documentType", {
        is: "cnpj",
        then: () =>
          yup
            .string()
            .required("Número do documento é obrigatório!")
            .test({
              name: "isValid",
              exclusive: false,
              params: {},
              message: "CNPJ inválido",
              test: function (value) {
                return validateCNPJ(value || "");
              },
            }),
      }),

      documentType: yup
        .string()
        .required("Escolher um tipo de documento é obrigatório!"),
      email: yup
        .string()
        .email("Formato de email inválido.")
        .required("Email é obrigatório!"),
    }),

    onSubmit: (values) => updateSettings(values),
  });

  const bankForm = useFormik({
    initialValues: {
      id: null,
      bankCode: "",
      agNumber: "",
      accNumber: "",
      accountType: "",
      responsible: "",
      document: "",
      pix: "",
    },

    onSubmit: (values) => updateBankData(values),
  });

  const formPassword = useFormik({
    initialValues: {
      // password: "",
      newPassword: "",
      passwordConfirmation: "",
    },

    validationSchema: yup.object({
      // password: yup.string().required("Senha é obrigatória!"),

      newPassword: yup
        .string()
        .min(8, "A nova senha deve conter no mínimo 8 caracteres.")
        .required("Nova senha é obrigatória!"),

      passwordConfirmation: yup
        .string()
        .required("Confirmação da nova senha é obrigatoria!")
        .oneOf(
          [yup.ref("newPassword"), ""],
          "As novas senhas devem ser iguais."
        ),
    }),

    onSubmit: (values) => updatePassword(values),
  });

  const updatePassword = async (values) => {
    setSaveLoading(true);

    try {
      const { data } = await api.put(`/affiliates/affiliates/${user.id}`, {
        data: {
          attributes: {
            password: values.newPassword,
            passwordConfirmation: values.passwordConfirmation,
          },
        },
      });
      if (data) {
        toast.success("Senha atualizada com sucesso!");
        signOut();
      }
    } catch (error) {
      //console.log(error);
      toast.error("Ocorreu um erro ao tentar atualizar sua senha.");
    } finally {
      setSaveLoading(false);
    }
  };

  const updateSettings = async (values) => {
    setSaveLoading(true);

    try {
      const { data } = await api.put(`/affiliates/affiliates/${user.id}`, {
        data: {
          attributes: {
            name: values.name.trim(),
            contact_number: unmask(values.phone.trim()),
            document:
              values.documentType === "cpf"
                ? unmask(values.cpf)
                : values.documentType === "cnpj"
                ? unmask(values.cnpj)
                : null,
          },
        },
      });
      if (data) {
        updateAffiliated(data);
        toast.success("Dados da conta atualizados com sucesso!");
      }
    } catch (error) {
      //console.log(error);
      toast.error("Ocorreu um erro ao tentar atualizar os dados da conta.");
    } finally {
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    getBankData();
  }, []);

  const getBankData = async () => {
    try {
      const { data } = await api.get(
        `/affiliates/affiliate_bank_details/${user.id}`
      );
      if (data?.data) {
        // updateAffiliated(data);
        console.log(data.data);
        bankForm.setValues({
          id: data.data.id,
          accNumber: data.data.attributes.account,
          accountType:
            data.data.attributes.accountType === "conta_poupanca"
              ? "poupanca"
              : "corrente",
          agNumber: data.data.attributes.agency,
          bankCode: data.data.attributes.bankCode,
          document: data.data.attributes.documentNumber,
          responsible: data.data.attributes.responsible,
          pix: data.data.attributes.pixCode,
        });

        console.log(bankForm.values);
      }
    } catch (error) {
      toast.error("Ocorreu um erro ao tentar carregar os dados bancários.");
    } finally {
    }
  };

  const updateBankData = async (values) => {
    setSaveLoading(true);

    try {
      if (values.id) {
        const { data } = await api.put(
          `/affiliates/affiliate_bank_details/${values.id}`,
          {
            data: {
              attributes: {
                responsible: values?.responsible,
                document_number: unmask(values?.document),
                bank_code: values?.bankCode,
                agency: values?.agNumber,
                account: values?.accNumber,
                account_type: values?.accountType === "corrente" ? 0 : 1,
                pix_code: values?.pix,
              },
            },
          }
        );
        if (data) {
          // updateAffiliated(data);
          toast.success("Dados bancários atualizados com sucesso!");
        }
      } else {
        const { data } = await api.post(
          `/affiliates/affiliate_bank_details/${user.id}`,
          {
            data: {
              attributes: {
                responsible: values?.responsible,
                document_number: unmask(values?.document),
                bank_code: values?.bankCode,
                agency: values?.agNumber,
                account: values?.accNumber,
                account_type: values?.accountType === "corrente" ? 1 : 2,
                pix_code: values?.pix,
              },
            },
          }
        );
        if (data) {
          // updateAffiliated(data);
          toast.success("Dados bancários atualizados com sucesso!");
        }
      }
    } catch (error) {
      //console.log(error);
      toast.error("Ocorreu um erro ao tentar atualizar os dados bancários.");
    } finally {
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    setTempPhone(form.values.phone);
  }, [form.values.phone]);

  useEffect(() => {
    console.log(form.values);
  }, [form]);

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  let width = isMobileDevice() ? 350 : 515;

  return (
    <AuthorizedLayout>
      {loading ? (
        // <Typography variant="h6">Carregando... </Typography>
        <Loader size={150} />
      ) : (
        <div style={{ margin: "24px" }}>
          <Box
            sx={{
              display: "flex",
              top: "10%",
              bgcolor: "white",
              minWidth: "650px",
              //height: "45%",
              flexDirection: "column",
              alignSelf: "center",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: 10,
              marginBottom: "50px",
              p: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                bgcolor: "white",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  margin: 0,
                  fontFamily: [
                    '"Roboto"',
                    '"Helvetica"',
                    '"Arial"',
                    "sans-serif",
                  ],
                  fontWeight: 500,
                  fontSize: "1.25rem",
                  lineHeight: 1.6,
                  letterSpacing: "0.0075em",
                  alignSelf: "flex-start",
                  marginTop: "-15px",
                  marginBottom: "15px",
                }}
              >
                Configurações de Dados da Conta
              </Typography>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  width: "100%",
                  marginBottom: "25px",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{
                    width: "20%",
                    backgroundColor:
                      section === "basic" ? "#539d65" : "#e6e9ed",
                    borderRadius: 25,
                    color: section === "basic" ? "#e6e9ed" : null,
                    ":hover": {
                      backgroundColor: "#566986",
                      color: "#e6e9ed",
                    },
                  }}
                  onClick={() => setSection("basic")}
                >
                  Dados Básicos
                </Button>
                <Button
                  sx={{
                    width: "20%",
                    backgroundColor: section === "bank" ? "#539d65" : "#e6e9ed",
                    borderRadius: 25,
                    color: section === "bank" ? "#e6e9ed" : null,
                    ":hover": {
                      backgroundColor: "#566986",
                      color: "#e6e9ed",
                    },
                  }}
                  onClick={() => setSection("bank")}
                >
                  Dados Bancários
                </Button>
              </div>

              {section === "basic" && (
                <Grid container spacing={1} sx={{ height: "100%" }}>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <InputWithLabel
                      label="Email"
                      value={"email"}
                      form={form}
                      helperText="Digite o email para login e controle interno"
                      variant="outlined"
                      disabled
                      validate
                    />
                    <InputWithLabel
                      label="Nome Completo"
                      value={"name"}
                      form={form}
                      helperText="Digite o seu nome para comunicação e controle interno"
                      variant="outlined"
                      validate
                    />
                    <InputWithLabel
                      label="Telefone de Contato"
                      value={"phone"}
                      form={form}
                      helperText="Digite o telefone a ser utilizado para contatos e informes gerais"
                      variant="outlined"
                      mask={
                        tempPhone.replace(/\s/g, "").length > 13
                          ? "(99) 9 9999-9999"
                          : "(99) 9999-99999"
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={logo}
                              alt="test"
                              style={{ width: "35px" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      validate
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      style={{ marginLeft: "2px" }}
                      fontSize={16}
                      fontWeight={600}
                      color={"#539d65"}
                    >
                      {"Tipo de Documento"}
                    </Typography>
                    <Typography
                      style={{ marginLeft: "2px", marginBottom: "5px" }}
                      fontSize={14}
                      color={"#566986"}
                    >
                      {"Selecione o tipo de documento a ser inserido"}
                    </Typography>
                    <RadioGroup
                      row
                      value={form.values.documentType}
                      style={{
                        marginLeft: "5px",
                        marginBottom: "25px",
                        height: "56px",
                      }}
                      onClick={(e) => {
                        if (e.target.value)
                          form.setFieldValue("documentType", e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="cpf"
                        control={<Radio />}
                        label="CPF"
                      />
                      <FormControlLabel
                        value="cnpj"
                        control={<Radio />}
                        label="CNPJ"
                      />
                    </RadioGroup>

                    {form.errors.documentType && form.touched.documentType && (
                      <Typography
                        style={{ marginBottom: "10px", marginTop: "-15px" }}
                        color={"red"}
                      >
                        {form.errors.documentType}
                      </Typography>
                    )}

                    <InputWithLabel
                      label="Número do Documento"
                      value={
                        form.values.documentType === "cpf"
                          ? "cpf"
                          : form.values.documentType === "cnpj"
                          ? "cnpj"
                          : null
                      }
                      mask={
                        form.values.documentType === "cpf"
                          ? "999.999.999-99"
                          : form.values.documentType === "cnpj"
                          ? "99.999.999/9999-99"
                          : ""
                      }
                      form={form}
                      helperText="Digite o número do seu documento"
                      variant="outlined"
                      validate
                    />

                    <Typography
                      style={{ marginLeft: "2px" }}
                      fontSize={16}
                      fontWeight={600}
                      color={"#539d65"}
                    >
                      {"Senha"}
                    </Typography>
                    <Typography
                      style={{ marginLeft: "2px", marginBottom: "5px" }}
                      fontSize={14}
                      color={"#566986"}
                    >
                      {
                        "Clique para iniciar o processo de alteração de senha de login"
                      }
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "56px",
                      }}
                    >
                      <Button
                        onClick={() => {
                          setModalPassword(true);
                        }}
                        variant="outlined"
                        style={{
                          width: "350px",
                          height: "40px",
                        }}
                      >
                        Alterar Senha
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              )}

              {section === "bank" && (
                <Grid container spacing={1} sx={{ height: "100%" }}>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <InputWithLabel
                      label="Código do Banco"
                      value={"bankCode"}
                      form={bankForm}
                      helperText="Digite o código identificador do serviço bancário utilizado"
                      variant="outlined"
                    />
                    <InputWithLabel
                      label="Agência"
                      value={"agNumber"}
                      form={bankForm}
                      helperText="Digite o número da agência"
                      variant="outlined"
                    />
                    <InputWithLabel
                      label="Conta"
                      value={"accNumber"}
                      form={bankForm}
                      helperText="Digite o número da conta bancária"
                      variant="outlined"
                    />
                    <InputWithLabel
                      label="Chave Pix"
                      value={"pix"}
                      form={bankForm}
                      helperText="Digite a sua chave Pix"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      style={{ marginLeft: "2px" }}
                      fontSize={16}
                      fontWeight={600}
                      color={"#539d65"}
                    >
                      {"Tipo de Conta"}
                    </Typography>
                    <Typography
                      style={{ marginLeft: "2px", marginBottom: "5px" }}
                      fontSize={14}
                      color={"#566986"}
                    >
                      {"Selecione o tipo de bancária utilizado"}
                    </Typography>
                    <RadioGroup
                      row
                      value={bankForm.values.accountType}
                      style={{
                        marginLeft: "5px",
                        marginBottom: "25px",
                        height: "56px",
                      }}
                      onClick={(e) => {
                        if (e.target.value)
                          bankForm.setFieldValue("accountType", e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="corrente"
                        control={<Radio />}
                        label="Corrente"
                      />
                      <FormControlLabel
                        value="poupanca"
                        control={<Radio />}
                        label="Poupança"
                      />
                    </RadioGroup>

                    <InputWithLabel
                      label="Nome do Responsável"
                      value={"responsible"}
                      form={bankForm}
                      helperText="Digite o nome do responsável pela conta bancária"
                      variant="outlined"
                    />

                    <InputWithLabel
                      label="Documento do Responsável"
                      value={"document"}
                      mask={"999.999.999-99"}
                      form={bankForm}
                      helperText="Digite o CPF do responsável pela conta bancária"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              )}

              <Button
                sx={{ height: "35px", p: 2, marginTop: "25px" }}
                variant="contained"
                onClick={() => {
                  if (section === "basic") form.handleSubmit();
                  else if (section === "bank") bankForm.handleSubmit();
                  else return null;
                }}
                disabled={saveLoading}
              >
                <Typography color={"white"} variant={"h6"}>
                  {section === "basic"
                    ? "Salvar dados básicos"
                    : section === "bank"
                    ? "Salvar dados bancários"
                    : "Salvar"}
                </Typography>
              </Button>
            </Box>
          </Box>

          {modalPassword && (
            <Modal
              open
              onClose={() => {
                setModalPassword(false);
                formPassword.setValues(formPassword.initialValues);
                formPassword.setErrors(formPassword.initialErrors);
                formPassword.setTouched(formPassword.initialTouched);
              }}
            >
              <Box
                style={{
                  background:
                    "linear-gradient(180deg, #f1fcf4 0%, #FFFFFF 100%)",
                  borderRadius: "20px",
                }}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: width,
                  // height: 200,
                  p: 2.5,
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant={"h6"} style={{ marginBottom: "10px" }}>
                    Alteração de Senha
                  </Typography>
                  {/* <InputWithLabel
                    validate
                    label="Senha Atual"
                    value={"password"}
                    form={formPassword}
                    type={"password"}
                    variant="outlined"
                  /> */}
                  <InputWithLabel
                    validate
                    label="Nova Senha"
                    value={"newPassword"}
                    form={formPassword}
                    type={"password"}
                    variant="outlined"
                  />
                  <InputWithLabel
                    validate
                    label="Confirmação da Nova Senha"
                    value={"passwordConfirmation"}
                    form={formPassword}
                    type={"password"}
                    variant="outlined"
                  />
                  <Button
                    sx={{
                      height: "35px",
                      p: 2,
                      m: 1,
                      alignSelf: "center",
                    }}
                    variant="contained"
                    disabled={saveLoading}
                    onClick={() => {
                      formPassword.handleSubmit();
                    }}
                  >
                    <Typography color={"white"} variant={"h6"}>
                      {saveLoading ? "Carregando" : "Salvar"}
                    </Typography>
                  </Button>
                </div>
              </Box>
            </Modal>
          )}
        </div>
      )}
    </AuthorizedLayout>
  );
};
