import React, { useContext, useState } from "react";
import { AuthContext } from "../../../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";

import iconUser from "../../../../assets/User.png";
import arrowIcon from "../../../../assets/Vector (7).png";
import iconHead from "../../../../assets/Headset.png";
import iconStar from "../../../../assets/Vector (8).png";
import iconCheck from "../../../../assets/Check.png";

import "./CheckList.css";

const CheckList = () => {
  const { user, checkComplete, checkList, checkLoading } =
    useContext(AuthContext);

  const contarTrues = (obj) => {
    let contador = 0;

    for (let key in obj) {
      if (obj[key] === true) {
        contador++;
      }
    }

    return contador;
  };

  const getFirstName = (fullName) => {
    if (!fullName) return ""; // Verifica se a string é vazia ou null
    return fullName.split(" ")[0];
  };

  const CardProgress = ({
    icon,
    title,
    description,
    buttonText,
    navigateTo,
    className,
    disable,
    check,
  }) => {
    const navigate = useNavigate();

    return (
      <div className={`cardProgress ${className}`}>
        <div className="containerText">
          <div className="cicle">
            <img src={iconCheck} alt="check icon" className="start" />
          </div>
          <div className="subText">
            <div className="title">
              <div className="cicleMobile">
                <img src={iconCheck} alt="check icon" className="start" />
              </div>
              <img src={icon} alt="icon" className="iconIMg"/>
              {title}
            </div>
            <div>{description}</div>
          </div>
        </div>
        <button
          onClick={() => navigate(navigateTo)}
          disabled={disable}
          style={{ backgroundColor: disable && check && "#a5aebd" }}
        >
          {buttonText}
          <img src={arrowIcon} alt="arrow icon" />
        </button>
      </div>
    );
  };

  const checkButton =
    user.profileFilled && user.activePlan && user.partnerDetailsFilled
      ? false
      : true;

  return (
    <div className="containerCheckProfile">
      <div className="containerHeader">
        <span className="title">{`Boas vindas ao SacGPT, ${getFirstName(
          user.name
        )}!  👋`}</span>
        <span className="text">
          Configure sua conta e comece a usar seu atendente virtual:
        </span>
      </div>

      <div className="containerTokens">
        <div className="containerHeaderTokens">
          <span className="text">
            <strong>{`Aproveite seus  ${(
              (user?.montlyTokensConsumed || 0) + (user?.monthlyTokensLeft || 0)
            ).toLocaleString()} tokens do teste gratuito`}</strong>
          </span>
          <div>
            <strong>
              {(user?.montlyTokensConsumed || 0).toLocaleString()}
            </strong>
            /
            {(
              (user?.montlyTokensConsumed || 0) + (user?.monthlyTokensLeft || 0)
            ).toLocaleString()}
          </div>
        </div>

        <div className="bar">
          <div className="fill">
            <span
              className="leads"
              style={{
                width: `${(
                  ((user?.montlyTokensConsumed || 0) /
                    ((user?.montlyTokensConsumed || 0) +
                      (user?.monthlyTokensLeft || 0))) *
                  100
                ).toFixed(2)}%`,
              }}
            ></span>
          </div>
        </div>
      </div>

      <div className="containerProgress">
        <div className="containerHeaderProgress">
          <div className="text">
            <strong>{contarTrues(checkList)}</strong>/3 CONCLUÍDOS
          </div>
        </div>

        <div className="bar">
          <div className="fill">
            <span
              className="leads"
              style={{
                width: `${((contarTrues(checkList) / 3) * 100).toFixed(2)}%`,
              }}
            ></span>
          </div>
        </div>
      </div>

      <CardProgress
        icon={iconHead}
        title="Configure o seu atendente"
        description="Agora é o momento de dar vida ao seu atendente virtual! Capriche nos detalhes, pois as informações fornecidas nos campos são como o cérebro do seu atendente virtual."
        buttonText="Configurar atendente"
        navigateTo="/atendente"
        className={checkList.partnerDetailsFilled && "check"}
      />

      <CardProgress
        icon={iconUser}
        title="Complete as informações do perfil"
        description="Adicione os dados complementares da conta e atualize sua foto de perfil."
        buttonText="Configurar meu perfil"
        navigateTo="/conta"
        className={checkList.profileFilled && "check"}
      />

      <CardProgress
        icon={iconStar}
        title="Escolha o seu plano"
        description="Escolha o plano que maior atende as necessidades do seu negócio. Utilize o nosso simulador para estimar quantos Tokens serão necessários para o seu atendente virtual."
        buttonText="Escolher o meu plano"
        navigateTo="/financeiro/step1"
        // className={checkList.activePlan && "check"}
        // disable={user?.profileFilled ? false : true}
        // check={true}
      />

      <button
        className="butonFInish"
        onClick={() => checkComplete(user.id)}
        style={{ backgroundColor: checkButton && "#a5aebd" }}
        disabled={checkButton}
      >
        {checkLoading ? "Carregando..." : "Concluir configurações"}
      </button>
    </div>
  );
};

export default CheckList;
