import React, { useContext, useEffect, useState } from "react";
import { CheckRounded, ContentCopyRounded } from "@mui/icons-material";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { AuthContext } from "../../../../providers/AuthProvider";
import { toast } from "react-toastify";
import { TaskAlt, GroupAdd, Facebook, AttachMoney } from "@mui/icons-material";
import Twiter from "../../../../assets/X_logo.png";
import api from "../../../../utils/api";

import dashImage from "../../../../assets/altLogo.0723f95721eb4d62ffcf (1).png";

import "./Metrics.css";

const Metrics = () => {
  const { user } = useContext(AuthContext);
  const [icon, setIcon] = useState(<ContentCopyRounded />);
  const [shouldAnimate, setShouldAnimate] = useState(true);
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState({});

  const getMetrics = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/affiliates/dashboard`);

      if (data) {
        setReports(data.data);
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar os dados.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMetrics();
  }, []);

  console.log(reports);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const handleClick = async () => {
    setIcon(<CheckRounded />);

    toast.success("Link copiado com sucesso!");

    await delay(10000);

    setIcon(<ContentCopyRounded />);
  };

  function NumberTransition(props) {
    const [value, setValue] = useState(0);
    const [moneyType, setMoneyType] = useState(false);

    useEffect(() => {
      const { TARGET_NUMBER, MONEY } = props;
      setMoneyType(MONEY);
      let interval;
      const step = Math.ceil(TARGET_NUMBER / 100);
      interval = setInterval(() => {
        if (shouldAnimate) {
          if (value + step >= TARGET_NUMBER) {
            clearInterval(interval);
            setValue(TARGET_NUMBER);
          } else {
            setValue((prevValue) => prevValue + step);
          }
        } else {
          clearInterval(interval);
        }
      }, 10);

      return () => {
        clearInterval(interval);
      };
    }, [props, value]);

    return (
      <div className="titleTotal">{moneyType ? `R$ ${value},00` : value}</div>
    );
  }

  function extrairNomeAtePrimeiroEspaco(nomeCompleto) {
    const primeiroEspacoIndex = nomeCompleto.indexOf(" ");

    if (primeiroEspacoIndex === -1) {
      return nomeCompleto;
    }

    const nomeAtePrimeiroEspaco = nomeCompleto.substring(
      0,
      primeiroEspacoIndex
    );

    return nomeAtePrimeiroEspaco;
  }

  return (
    <div style={{ margin: "24px" }}>
      <div className="containerHeaderMetrics">
        <div className="containerLink boxShadon">
          <div
            style={{
              display: "flex",
              alignContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              className="title"
              style={{
                fontWeight: "1000",
                textTransform: "none",
                fontSize: "20px",
                display: "flex",
                justifyContent: "center",
                gap: "5px",
              }}
            >
              {`Olá `}
              <span style={{ color: "#539d65" }}>
                {extrairNomeAtePrimeiroEspaco(user.name)}
              </span>
              {`, bom te ver de novo!`}
            </div>

            <div
              className="subtitle"
              style={{ alignSelf: "center", width: "70%", textAlign: "center" }}
            >
              Confira o que você perdeu enquanto estava fora:
            </div>

            <div
              style={{
                border: "1px solid #539d65",
                width: "85%",
                alignSelf: "center",
                margin: "20px 0",
              }}
            ></div>
          </div>

          <div className="title">Convide novos usuários para a plataforma!</div>
          <div className="subtitle">
            Abaixo está o seu link de afiliado para divulgação:
          </div>
          <button
            onClick={() => {
              handleClick();
              navigator.clipboard.writeText(user?.affiliateUrl);
            }}
            className="link"
          >
            {user?.affiliateUrl}
          </button>

          <div
            style={{ color: "#A7A7A7", margin: "8px 0", fontSize: "0.9rem" }}
          >
            Clique no link acima para copiá-lo!
          </div>

          {/* <div className="subtitle">Compartilhe</div>

          <div className="containerSocial">
            <div
              className="buttonSocial"
              style={{ backgroundColor: "#476bb7" }}
            >
              <Facebook sx={{ fontSize: "30px" }} />
              <div>Facebook</div>
            </div>

            <div
              className="buttonSocial"
              style={{ backgroundColor: "#000000" }}
            >
              <img src={Twiter} alt="twiter" style={{ width: "24px" }} />
              <div>x.com</div>
            </div>
          </div> */}
        </div>

        <div
          className="boxShadon"
          style={{
            padding: "30px",
            flex: 1,
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div className="containerGanhos">
            {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <div className="title" style={{ fontSize: "1rem" }}>
                  R$
                </div>
                <div className="title">2341,26</div>
              </div>
              <div className="subtitle">total de comissão acumulada</div>
            </div> */}
            <div className="conatinerIndicados">
              <div
                className="title"
                style={{
                  fontSize: "0.8rem",
                  marginBottom: "15px",
                  color: "rgba(255, 255, 255, 0.6)",
                }}
              >
                Análise de vendas
              </div>
              <div className="containerCards">
                <div className="card">
                  <div className="titleCard">{reports?.salesAnalysis?.closing}</div>
                  <div className="subtitleCard">Fechados</div>
                </div>

                <div className="card">
                  <div className="titleCard">{reports?.salesAnalysis?.financial}</div>
                  <div className="subtitleCard">Financeiro</div>
                </div>

                {/* <div className="card">
                  <div className="titleCard">317</div>
                  <div className="subtitleCard">Cadastrados</div>
                </div> */}
              </div>
            </div>
          </div>
          <div style={{}}>
            <img src={dashImage} style={{ width: "100%" }} alt="logo" />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            gap: "25px",
            padding: "30px",
          }}
          className="boxShadon"
        >
          <div
            className="containerTotal flexDirecitonCOllum"
            style={{ flexDirection: "column" }}
          >
            <div className="cardTotal boxShadon">
              <div style={{ alignSelf: "flex-end" }}>
                <NumberTransition TARGET_NUMBER={reports?.salesFunnel?.new} />
                <div className="subtitleTotal">Novos Leads</div>
              </div>
              <div>
                <GroupAdd sx={{ fontSize: "30px", color: "#539d65" }} />
              </div>
            </div>
            <div className="cardTotal boxShadon">
              <div style={{ alignSelf: "flex-end" }}>
                <NumberTransition TARGET_NUMBER={reports?.salesFunnel?.closed} />
                <div className="subtitleTotal">Leads fechados</div>
              </div>
              <div>
                <TaskAlt sx={{ fontSize: "30px", color: "#539d65" }} />
              </div>
            </div>
            {/* <div className="cardTotal boxShadon">
              <div style={{ alignSelf: "flex-end" }}>
                <NumberTransition TARGET_NUMBER={230} MONEY={true} />
                <div className="subtitleTotal">Comissão</div>
              </div>
              <div>
                <AttachMoney sx={{ fontSize: "30px", color: "#539d65" }} />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Metrics;
