import { Box, Button, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Metrics from './Components/Metrics';

import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import api from "../../../utils/api";

import { Loader } from "../../../components/Loader";


import { AuthContext } from "../../../providers/AuthProvider";

export const DashboardSales = () => {
  const [loading, setLoading] = useState(false);

 
  // const getMetrics = async () => {
  //   setLoading(true);
  //   try {
  //     const { data } = await api.get(`/partners/partner_reports`);

  //     if (data) {
  //       setReports(data.data.reports);
  //     }
  //   } catch (error) {
  //     //console.log(error);
  //     toast.error("Não foi possível carregar os dados.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   getMetrics();
  // }, []);



  return (
    <AuthorizedLayout>
      {loading ? (
        // <Typography variant="h6">Carregando... </Typography>
        <Loader size={150} />
      ) : (
        <>
          {/* <Box
            sx={{
              display: "flex",
              top: "10%",
              bgcolor: "white",
              minWidth: "650px",
              //height: "45%",
              flexDirection: "column",
              alignSelf: "center",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: 10,
              p: 4,
              marginBottom: 2,
              borderRadius: 5,
            }}
          >
            <Metrics />
          </Box> */}
          <Metrics />
        </>
      )}
    </AuthorizedLayout>
  );
};
