import React, { useState, useContext, useEffect, useRef } from "react";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout/index";
import { toast } from "react-toastify";
import { Box, Button, Modal, Typography } from "@mui/material";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { AuthContext } from "../../../providers/AuthProvider";
import { useFormik } from "formik";
import * as yup from "yup";
import api from "../../../utils/api";
import { Loader } from "../../../components/Loader";
import Step1 from "./Components/Step1";
import Step2 from "./Components/Step2";
import Step3 from "./Components/Step3";
import Step4 from "./Components/Step4";
import Step5 from "./Components/Step5";
import Step6 from "./Components/Step6";

import iconMensage from "../../../assets/Vector (5).png";
import iconUsers from "../../../assets/Vector (4).png";

import "./Attendant.css";

const optionsArray = [
  "Agendar uma reunião",
  "Adquirir email do cliente",
  "Outro",
];

const toneArray = [
  "Respeitoso & Empático",
  "Animado & Engajador",
  "Profissional & Firme",
  "Atencioso & Calmo",
  "Encorajador & Motivador",
  "Compassivo & Compreensivo",
  "Divertido & Cativante",
  "Cortês & Gentil",
  "Solidário & Amigável",
  "Eficiente & Rápido",
  "Confiável & Preciso",
  "Assertivo & Decisivo",
  "Perspicaz & Observador",
  "Outro",
];

const daysArray = [
  "Segunda-feira",
  "Terça-feira",
  "Quarta-feira",
  "Quinta-feira",
  "Sexta-feira",
  "Sábado",
  "Domingo",
];

const menuArray = [
  "Dados Básicos",
  "",
  "Produtos e Serviços",
  "",
  "Estratégia de Atendimento",
  "",
  "Simulador do Atendente",
  "",
  "Vincular Contas",
];

const Attendant = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { value } = location.state || {};

  const { user, checkList, checkLoading, checkComplete, updatePartnerDetail } =
    useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [checkSave, setCheckSave] = useState(true);
  const [modalWarning, setModalWarning] = useState(false);
  const [checkDataBasic, setDataBasic] = useState(false);
  const [menu, setMenu] = useState();
  const [menuSelected, setMenuSelected] = useState(
    value || {
      menu: "Dados Básicos",
      index: 0,
    }
  );

  useEffect(() => {
    if (value) {
      setMenuSelected(value);
    }
  }, [value]);

  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  const getSettings = async () => {
    setLoading(true);
    try {
      if (user.partnerDetailId) {
        const { data } = await api.get(
          `partners/partner_details/${user.partnerDetailId}`
        );

        if (data) {
          let valorExtra = "";
          const valoresFiltrados = data.data?.attributes?.companyObjectives
            ? Object.keys(data.data.attributes.companyObjectives).reduce(
                (acc, key) => {
                  if (
                    optionsArray.includes(
                      data.data.attributes.companyObjectives[key]
                    )
                  ) {
                    acc[key] = data.data.attributes.companyObjectives[key];
                  } else {
                    acc[key] = "Outro";
                    valorExtra = data.data.attributes.companyObjectives[key];
                  }

                  return acc;
                },
                []
              )
            : [];

          let toneExtra = "";
          const toneVoiceFiltrados = data.data?.attributes?.toneVoice
            ? Object.keys(data.data.attributes.toneVoice).reduce((acc, key) => {
                if (toneArray.includes(data.data.attributes.toneVoice[key])) {
                  acc[key] = data.data.attributes.toneVoice[key];
                } else {
                  acc[key] = "Outro";
                  toneExtra = data.data.attributes.toneVoice[key];
                }

                return acc;
              }, [])
            : [];
          // form.setValues({
          //   ...data.data.attributes,
          //   companyObjectives: valoresFiltrados,
          //   companyObjectivesExtra: valorExtra,
          //   toneVoice: toneVoiceFiltrados,
          //   toneVoiceExtra: toneExtra,
          //   catalogLink: data?.data?.attributes?.catalogLink || "",
          // });
          formStep1.setValues({
            nameAttendant: data.data.attributes?.nameAttendant,
            companyName: data.data.attributes?.companyName,
            companyNiche: data.data.attributes?.companyNiche,
          });

          if (
            data.data.attributes?.nameAttendant &&
            data.data.attributes?.companyName &&
            data.data.attributes?.companyNiche
          ) {
            setDataBasic(true);
          }

          formStep3.setValues({
            companyServices: data.data.attributes?.companyServices,
            companyProducts: data.data.attributes?.companyProducts,
            catalogLink: data?.data?.attributes?.catalogLink || "",
            servedRegion: data.data.attributes?.servedRegion,
            keyDifferentials: data.data.attributes?.keyDifferentials,
            companyContact: data.data.attributes?.companyContact,
          });
          formStep4.setValues({
            companyObjectives: valoresFiltrados,
            companyObjectivesExtra: valorExtra,
            marketingChannels: data.data.attributes?.marketingChannels,
            twitterXLink: data.data.attributes?.twitterXLink,
            youtubeLink: data.data.attributes?.youtubeLink,
            facebookLink: data.data.attributes?.facebookLink,
            instagramLink: data.data.attributes?.instagramLink,
            preferentialLanguage: data.data.attributes?.preferentialLanguage,
            toneVoice: toneVoiceFiltrados,
            toneVoiceExtra: toneExtra,
          });
          formStep6.setValues({
            connectedWithGoogle: data.data.attributes?.connectedWithGoogle,
          });
        }
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar as configurações.");
    } finally {
      setLoading(false);
      setCheckSave(true);
    }
  };

  useEffect(() => {
    getSettings();
    // getScheduleSettings();
  }, []);

  // const form = useFormik({
  //   initialValues: {
  //     nameAttendant: "",
  //     companyName: "",
  //     companyNiche: "",
  //     servedRegion: "",
  //     companyProducts: "",
  //     companyServices: "",
  //     companyContact: "",
  //     companyObjectives: [],
  //     companyObjectivesExtra: "",
  //     marketingChannels: "",
  //     twitterXLink: "",
  //     youtubeLink: "",
  //     facebookLink: "",
  //     instagramLink: "",
  //     keyDifferentials: "",
  //     toneVoice: [],
  //     toneVoiceExtra: "",
  //     preferentialLanguage: "",
  //     catalogLink: "",
  //     connectedWithGoogle: false,
  //   },

  //   validationSchema: yup.object({
  //     nameAttendant: yup
  //       .string()
  //       .required("O campo Nome do/a Atendente é obrigatório!")
  //       .min(3, "Nome deve ter pelo menos 3 caracteres"),
  //     companyName: yup
  //       .string()
  //       .required("O campo Nome Fantasia do Negócio é obrigatório!")
  //       .min(3, "Nome deve ter pelo menos 3 caracteres"),
  //     companyNiche: yup
  //       .string()
  //       .required("O campo Nicho do Negócio é obrigatório!")
  //       .min(3, "Nome deve ter pelo menos 3 caracteres"),
  //   }),

  //   onSubmit: () => updateSettings(),
  // });

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const sectionSimulator = useRef(null)

  const scrollToSection1 = () => {
    section1Ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToSection2 = () => {
    section2Ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToSection3 = () => {
    section3Ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollSimulator = () => {
    sectionSimulator.current.scrollIntoView({ behavior: "smooth" });
  };


  const formStep1 = useFormik({
    initialValues: {
      nameAttendant: "",
      companyName: "",
      companyNiche: "",
    },

    validationSchema: yup.object({
      nameAttendant: yup
        .string()
        .required("O campo Nome do/a Atendente é obrigatório!")
        .min(3, "Nome deve ter pelo menos 3 caracteres"),
      companyName: yup
        .string()
        .required("O campo Nome Fantasia do Negócio é obrigatório!")
        .min(3, "Nome deve ter pelo menos 3 caracteres"),
      companyNiche: yup
        .string()
        .required("O campo Nicho do Negócio é obrigatório!")
        .min(3, "Nome deve ter pelo menos 3 caracteres"),
    }),

    onSubmit: (values) => updateStep1(values),
  });

  const updateStep1 = async (values) => {
    setSaveLoading(true);
    try {
      if (user.partnerDetailId) {
        const { data } = await api.put(
          `partners/partner_details/${user.partnerDetailId}`,
          {
            data: {
              attributes: {
                nameAttendant: values.nameAttendant,
                companyName: values.companyName,
                companyNiche: values.companyNiche,
              },
            },
          }
        );

        if (data) {
          // Adding it's token to all requests for now on.
          toast.success("Configurações atualizadas com sucesso!");
          getSettings();
          await checkComplete(user.partnerDetailId);
          handleNavigate();
          scrollToSection1();
        }
      } else {
        const { data } = await api.post(`partners/partner_details/`, {
          data: {
            attributes: {
              partnerId: user.id,
              nameAttendant: values.nameAttendant,
              companyName: values.companyName,
              companyNiche: values.companyNiche,
            },
          },
        });

        if (data) {
          // Adding it's token to all requests for now on.
          toast.success("Configurações atualizadas com sucesso!");
          updatePartnerDetail(data);
          getSettings();
          await checkComplete(user.id);
          handleNavigate();
          scrollToSection1();
        }
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível atualizar as configurações.");
    } finally {
      setSaveLoading(false);
      setCheckSave(true);
    }
  };

  const formStep3 = useFormik({
    initialValues: {
      companyServices: "",
      companyProducts: "",
      catalogLink: "",
      servedRegion: "",
      companyContact: "",
      keyDifferentials: "",
    },

    validationSchema: yup.object({}),

    onSubmit: (values) => updateStep3(values),
  });

  const updateStep3 = async (values) => {
    setSaveLoading(true);

    try {
      if (user.partnerDetailId) {
        const { data } = await api.put(
          `partners/partner_details/${user.partnerDetailId}`,
          {
            data: {
              attributes: {
                companyServices: values.companyServices,
                companyProducts: values.companyProducts,
                catalogLink: values.catalogLink,
                servedRegion: values.servedRegion,
                companyContact: values.companyContact,
                key_differentials: values.keyDifferentials,
              },
            },
          }
        );

        if (data) {
          // Adding it's token to all requests for now on.
          toast.success("Configurações atualizadas com sucesso!");
          getSettings();
          await checkComplete(user.partnerDetailId);
          handleNavigate();
          scrollToSection2();
        }
      } else {
        const { data } = await api.post(`partners/partner_details/`, {
          data: {
            attributes: {
              partnerId: user.id,
              companyServices: values.companyServices,
              companyProducts: values.companyProducts,
              catalogLink: values.catalogLink,
              servedRegion: values.servedRegion,
              companyContact: values.companyContact,
              key_differentials: values.keyDifferentials,
            },
          },
        });

        if (data) {
          // Adding it's token to all requests for now on.
          toast.success("Configurações atualizadas com sucesso!");
          updatePartnerDetail(data);
          getSettings();
          await checkComplete(user.id);
          handleNavigate();
          scrollToSection2();
        }
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível atualizar as configurações.");
    } finally {
      setSaveLoading(false);
      setCheckSave(true);
    }
  };

  const formStep4 = useFormik({
    initialValues: {
      companyObjectives: [],
      companyObjectivesExtra: "",
      marketingChannels: "",
      twitterXLink: "",
      youtubeLink: "",
      facebookLink: "",
      instagramLink: "",
      toneVoice: [],
      toneVoiceExtra: "",
      preferentialLanguage: "",
    },

    validationSchema: yup.object({}),

    onSubmit: (values) => updateStep4(values),
  });

  const updateStep4 = async (values) => {
    setSaveLoading(true);

    try {
      if (user.partnerDetailId) {
        const { data } = await api.put(
          `partners/partner_details/${user.partnerDetailId}`,
          {
            data: {
              attributes: {
                companyObjectives: values?.companyObjectives?.map((item) => {
                  if (item === "Outro") {
                    return values.companyObjectivesExtra;
                  } else {
                    return item;
                  }
                }),
                // marketing_channels: values.marketingChannels,
                twitterXLink: values.twitterXLink,
                youtubeLink: values.youtubeLink,
                facebookLink: values.facebookLink,
                instagramLink: values.instagramLink,
                tone_voice: values?.toneVoice?.map((item) => {
                  if (item === "Outro") {
                    return values.toneVoiceExtra;
                  } else {
                    return item;
                  }
                }),
                preferentialLanguage: values.preferentialLanguage,
              },
            },
          }
        );

        if (data) {
          // Adding it's token to all requests for now on.
          toast.success("Configurações atualizadas com sucesso!");
          getSettings();
          await checkComplete(user.partnerDetailId);
          handleNavigate();
          scrollToSection3();
        }
      } else {
        const { data } = await api.post(`partners/partner_details/`, {
          data: {
            attributes: {
              partnerId: user.id,
              companyObjectives: values?.companyObjectives?.map((item) => {
                if (item === "Outro") {
                  return values.companyObjectivesExtra;
                } else {
                  return item;
                }
              }),
              // marketing_channels: values.marketingChannels,
              twitterXLink: values.twitterXLink,
              youtubeLink: values.youtubeLink,
              facebookLink: values.facebookLink,
              instagramLink: values.instagramLink,
              tone_voice: values?.toneVoice?.map((item) => {
                if (item === "Outro") {
                  return values.toneVoiceExtra;
                } else {
                  return item;
                }
              }),
              preferentialLanguage: values.preferentialLanguage,
            },
          },
        });

        if (data) {
          // Adding it's token to all requests for now on.
          toast.success("Configurações atualizadas com sucesso!");
          updatePartnerDetail(data);
          getSettings();
          await checkComplete(user.id);
          handleNavigate();
          scrollToSection3();
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Não foi possível atualizar as configurações.");
    } finally {
      setSaveLoading(false);
      setCheckSave(true);
    }
  };

  const formStep6 = useFormik({
    initialValues: {
      connectedWithGoogle: false,
    },

    validationSchema: yup.object({}),

    onSubmit: (values) => updateStep6(values),
  });

  const updateStep6 = async (values) => {
    setSaveLoading(true);

    try {
      if (user.partnerDetailId) {
        const { data } = await api.put(
          `partners/partner_details/${user.partnerDetailId}`,
          {
            data: {
              attributes: {
                catalogLink: values.catalogLink,
              },
            },
          }
        );

        if (data) {
          // Adding it's token to all requests for now on.
          toast.success("Configurações atualizadas com sucesso!");
          getSettings();
          await checkComplete(user.partnerDetailId);
          handleNavigate();
        }
      } else {
        const { data } = await api.post(`partners/partner_details/`, {
          data: {
            attributes: {
              partnerId: user.id,
              catalogLink: values.catalogLink,
            },
          },
        });

        if (data) {
          // Adding it's token to all requests for now on.
          toast.success("Configurações atualizadas com sucesso!");
          updatePartnerDetail(data);
          getSettings();
          await checkComplete(user.id);
          handleNavigate();
        }
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível atualizar as configurações.");
    } finally {
      setSaveLoading(false);
      setCheckSave(true);
    }
  };

  // const updateSettings = async () => {
  //   setSaveLoading(true);

  //   try {
  //     if (user.partnerDetailId) {
  //       const { data } = await api.put(
  //         `partners/partner_details/${user.partnerDetailId}`,
  //         {
  //           data: {
  //             attributes: {
  //               nameAttendant: form.values.nameAttendant,
  //               companyName: form.values.companyName,
  //               companyNiche: form.values.companyNiche,
  //               servedRegion: form.values.servedRegion,
  //               companyServices: form.values.companyServices,
  //               companyProducts: form.values.companyProducts,
  //               companyContact: form.values.companyContact,
  //               companyObjectives: form?.values?.companyObjectives?.map(
  //                 (item) => {
  //                   if (item === "Outro") {
  //                     return form.values.companyObjectivesExtra;
  //                   } else {
  //                     return item;
  //                   }
  //                 }
  //               ),
  //               // marketing_channels: form.values.marketingChannels,
  //               twitterXLink: form.values.twitterXLink,
  //               youtubeLink: form.values.youtubeLink,
  //               facebookLink: form.values.facebookLink,
  //               instagramLink: form.values.instagramLink,
  //               key_differentials: form.values.keyDifferentials,
  //               tone_voice: form?.values?.toneVoice?.map((item) => {
  //                 if (item === "Outro") {
  //                   return form.values.toneVoiceExtra;
  //                 } else {
  //                   return item;
  //                 }
  //               }),
  //               preferentialLanguage: form.values.preferentialLanguage,
  //               catalogLink: form.values.catalogLink,
  //             },
  //           },
  //         }
  //       );

  //       if (data) {
  //         // Adding it's token to all requests for now on.
  //         toast.success("Configurações atualizadas com sucesso!");
  //         getSettings();
  //         await checkComplete(user.partnerDetailId);
  //         handleNavigate();
  //       }
  //     } else {
  //       const { data } = await api.post(`partners/partner_details/`, {
  //         data: {
  //           attributes: {
  //             partnerId: user.id,
  //             nameAttendant: form.values.nameAttendant,
  //             companyName: form.values.companyName,
  //             companyNiche: form.values.companyNiche,
  //             servedRegion: form.values.servedRegion,
  //             companyServices: form.values.companyServices,
  //             companyProducts: form.values.companyProducts,
  //             companyContact: form.values.companyContact,
  //             companyObjectives: form?.values?.companyObjectives?.map(
  //               (item) => {
  //                 if (item === "Outro") {
  //                   return form.values.companyObjectivesExtra;
  //                 } else {
  //                   return item;
  //                 }
  //               }
  //             ),
  //             // marketing_channels: form.values.marketingChannels,
  //             twitterXLink: form.values.twitterXLink,
  //             youtubeLink: form.values.youtubeLink,
  //             facebookLink: form.values.facebookLink,
  //             instagramLink: form.values.instagramLink,
  //             key_differentials: form.values.keyDifferentials,
  //             tone_voice: form?.values?.toneVoice?.map((item) => {
  //               if (item === "Outro") {
  //                 return form.values.toneVoiceExtra;
  //               } else {
  //                 return item;
  //               }
  //             }),
  //             preferentialLanguage: form.values.preferentialLanguage,
  //             catalogLink: form.values.catalogLink,
  //           },
  //         },
  //       });

  //       if (data) {
  //         // Adding it's token to all requests for now on.
  //         toast.success("Configurações atualizadas com sucesso!");
  //         updatePartnerDetail(data);
  //         getSettings();
  //         await checkComplete(user.id);
  //         handleNavigate();
  //       }
  //     }
  //   } catch (error) {
  //     //console.log(error);
  //     toast.error("Não foi possível atualizar as configurações.");
  //   } finally {
  //     setSaveLoading(false);
  //   }
  // };

  const handleNavigate = () => {
    switch (menuSelected.menu) {
      case "Dados Básicos":
        navigate("/atendente", {
          state: {
            value: {
              menu: "Produtos e Serviços",
              index: 2,
            },
          },
        });
        break;
      case "Produtos e Serviços":
        navigate("/atendente", {
          state: {
            value: {
              menu: "Estratégia de Atendimento",
              index: 4,
            },
          },
        });
        break;
      case "Estratégia de Atendimento":
        navigate("/atendente", {
          state: {
            value: {
              menu: "Vincular Contas",
              index: 8,
            },
          },
        });
        break;
      case "Simulador do Atendente":
        return null;

      case "Vincular Contas":
        if (!checkList.profileFilled && !checkList.activePlan) {
          navigate("/conta");
        } else if (checkList.profileFilled && !checkList.activePlan) {
          navigate("/financeiro/step1");
        } else if (!checkList.profileFilled && checkList.activePlan) {
          navigate("/conta");
        }
        break;

      default:
        return null;
    }
  };

  const renderItemMenu = (name) => {
    switch (name) {
      case "Dados Básicos":
        return (
          <Step1
            form={formStep1}
            saveLoading={saveLoading}
            setMenuSelected={setMenuSelected}
            checkList={checkList}
            setCheckSave={setCheckSave}
            scrollSimulator={scrollSimulator}
          />
        );

      case "Produtos e Serviços":
        return (
          <Step3
            form={formStep3}
            saveLoading={saveLoading}
            setMenuSelected={setMenuSelected}
            checkList={checkList}
            setCheckSave={setCheckSave}
            scrollSimulator={scrollSimulator}
          />
        );
      case "Estratégia de Atendimento":
        return (
          <Step4
            form={formStep4}
            saveLoading={saveLoading}
            setMenuSelected={setMenuSelected}
            checkList={checkList}
            setCheckSave={setCheckSave}
            scrollSimulator={scrollSimulator}
          />
        );
      case "Simulador do Atendente":
        return (
          <Step5
            saveLoading={saveLoading}
            setMenuSelected={setMenuSelected}
            menuSelected={menuSelected}
            setCheckSave={setCheckSave}
          />
        );
      case "Vincular Contas":
        return (
          <Step6
            form={formStep6}
            saveLoading={saveLoading}
            setMenuSelected={setMenuSelected}
            checkList={checkList}
            setCheckSave={setCheckSave}
            scrollSimulator={scrollSimulator}
          />
        );
      default:
        <Step1
          form={formStep1}
          saveLoading={saveLoading}
          setMenuSelected={setMenuSelected}
          checkList={checkList}
          setCheckSave={setCheckSave}
          scrollSimulator={scrollSimulator}
        />;
    }
  };

  const handleMenu = (item) => {
    if (
      item.menu !== "Dados Básicos" &&
      !checkList.partnerDetailsFilled &&
      !checkDataBasic
    ) {
      toast.error(
        "Atenção: preencha primeiro o nome do (a) atendente, o nome fantasia do negócio e o nicho do negócio para ter acesso aos itens do menu!"
      );
    } else {
      if (!checkSave) {
        setModalWarning(true);
        setMenu(item);
      } else {
        setMenuSelected(item);
      }
    }
  };

  const nextPage = () => {
    setModalWarning(false);
    setMenuSelected(menu);
    setCheckSave(true);
  };

  return (
    <AuthorizedLayout>
      <div className="containerAttendant">
        <div className="containerMenu">
          {menuArray.map((item, index) => (
            <div
              key={index}
              className={`${item !== "" ? "menu" : "line"} ${
                item === menuSelected.menu && "menuSelected"
              } ${
                (index === menuSelected.index - 1 ||
                  index === menuSelected.index + 1) &&
                "none"
              } ${
                item !== "Dados Básicos" &&
                !checkList.partnerDetailsFilled &&
                !checkDataBasic &&
                "disabledMenus"
              }`}
              onClick={() => handleMenu({ menu: item, index: index })}
            >
              {item}
            </div>
          ))}
        </div>

        <div className="contentWeb">{renderItemMenu(menuSelected.menu)}</div>

        {modalWarning && (
          <Modal
            open
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                setModalWarning("false");
              }
            }}
          >
            <Box
              style={{
                background: "linear-gradient(180deg, #f1fcf4 0%, #FFFFFF 100%)",
                borderRadius: "20px",
              }}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: isMobileDevice() ? 350 : 700,
                // height: 200,
                p: 2.5,
              }}
            >
              <div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    sx={{
                      margin: 0,
                      fontFamily: [
                        '"Roboto"',
                        '"Helvetica"',
                        '"Arial"',
                        "sans-serif",
                      ],
                      fontWeight: 500,
                      fontSize: "1.25rem",
                      lineHeight: 1.6,
                      letterSpacing: "0.0075em",
                      alignSelf: "flex-start",
                      marginTop: "15px",
                      marginBottom: "15px",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      fontSize={28}
                      fontWeight={600}
                      color={"#e52207"}
                    >
                      AVISO
                    </Typography>
                  </Typography>

                  <Typography
                    textAlign={"center"}
                    fontWeight={"600"}
                    style={{ marginBottom: "25px" }}
                    fontSize={20}
                  >
                    {
                      "Você tem alterações não salvas. Se você sair sem salvar, perderá todas as alterações feitas."
                    }
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Button
                      sx={{
                        height: "35px",
                        width: "140px",
                        p: 2,
                        m: 1,
                        alignSelf: "center",
                      }}
                      variant="contained"
                      disabled={saveLoading}
                      onClick={() => {
                        setModalWarning(false);
                      }}
                    >
                      <Typography color={"white"} variant={"h6"}>
                        Voltar
                      </Typography>
                    </Button>

                    <Button
                      sx={{
                        height: "35px",
                        width: "140px",
                        p: 2,
                        m: 1,
                        alignSelf: "center",
                        backgroundColor: "#e52207",
                      }}
                      variant="contained"
                      disabled={saveLoading}
                      onClick={() => {
                        nextPage();
                      }}
                    >
                      <Typography color={"white"} variant={"h6"}>
                        {"Entendi"}
                      </Typography>
                    </Button>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        )}

        <div className="contentMobile">
          <Step1
            form={formStep1}
            saveLoading={saveLoading}
            setMenuSelected={setMenuSelected}
            setCheckSave={setCheckSave}
            checkList={checkList}
            scrollSimulator={scrollSimulator}
          />

          {checkList.partnerDetailsFilled && user.partnerDetailsFilled ? (
            <>
              <Step3
                form={formStep3}
                saveLoading={saveLoading}
                setMenuSelected={setMenuSelected}
                section1Ref={section1Ref}
                setCheckSave={setCheckSave}
                checkList={checkList}
                scrollSimulator={scrollSimulator}
              />

              <Step4
                form={formStep4}
                saveLoading={saveLoading}
                setMenuSelected={setMenuSelected}
                section2Ref={section2Ref}
                setCheckSave={setCheckSave}
                checkList={checkList}
                scrollSimulator={scrollSimulator}
              />

              <Step5
                saveLoading={saveLoading}
                setMenuSelected={setMenuSelected}
                menuSelected={menuSelected}
                sectionSimulator={sectionSimulator}
              />

              <Step6
                form={formStep6}
                saveLoading={saveLoading}
                setMenuSelected={setMenuSelected}
                section3Ref={section3Ref}
                setCheckSave={setCheckSave}
                checkList={checkList}
                scrollSimulator={scrollSimulator}
              />
            </>
          ) : (
            <div className="containerInfoSimulator">
              {/* <div className="titlePage" style={{ color: "#102649" }}>
                Simulador do Atendente
              </div> */}
              <strong>Atenção:</strong>
              <div>
                Preencha primeiro o nome do (a) atendente, o nome fantasia do
                negócio e o nicho do negócio para ter acesso a todas as
                configurações e ao Simulador do atendente!
              </div>
            </div>
          )}
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default Attendant;
