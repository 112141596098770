import { Circle, Close, Info, WhatsApp, ZoomIn } from "@mui/icons-material";
import { Button, Chip, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import api, { devEnv } from "../../../utils/api";
import { Tooltip } from "@mui/material";
import { Loader } from "../../../components/Loader";
import { useNavigate } from "react-router-dom";
import ModalAlert from "../../../components/ModalAlert";
import parsePhone from "../../../utils/parsePhone";
import moment from "moment";
import parsePhoneAdmin from "../../../utils/parsePhoneAdmin";

export const ClientListSales = () => {
  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  // const [clients, setClients] = useState([
  //   {
  //     id: 1,
  //     name: "João Victor Capistrano da Silva",
  //     phone: "(22) 9 8813-3721",
  //   },
  //   { id: 2, name: "Heloise Aline Viana", phone: "(77) 9 8701-9762" },
  //   { id: 3, name: "Erick Lucca Cláudio Farias", phone: "(86) 9 8404-0456" },
  //   { id: 4, name: "Hugo Paulo da Silva", phone: "(63) 9 8764-5360" },
  //   { id: 5, name: "Roberto Noah Igor Martins", phone: "(19) 9 8251-0940" },
  //   {
  //     id: 6,
  //     name: "Marcos Vinicius Oliver Vicente da Costa",
  //     phone: "(98) 9 9610-4492",
  //   },
  //   {
  //     id: 7,
  //     name: "Bernardo Mário Benjamin Carvalho",
  //     phone: "(82) 9 8742-7997",
  //   },
  // ]);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clientId, setClientId] = useState(null);
  const [modalAlert, setModalAlert] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   console.log(selectedClient);
  // }, [selectedClient]);

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
      },
    },

    {
      name: "clientName",
      label: "Nome",
      options: {
        filter: false,
      },
    },

    {
      name: "clientEmail",
      label: "Email",
      options: {
        filter: false,
      },
    },

    {
      name: "phoneNumber",
      label: "Telefone",
      options: {
        filter: false,
        customBodyRender: (phoneNumber, { rowData }) => {
          return parsePhoneAdmin(phoneNumber);
        },
      },
    },

    {
      name: "subscriptionT",
      label: "Plano Atual",
      options: {
        filter: true,
        filterOptions: {
          fullWidth: true,
        },
      },
    },

    {
      name: "tokenUsage",
      label: "Uso de Tokens",
      options: {
        filter: false,
      },
    },

    {
      name: "statusT",
      label: "Status",
      options: {
        filter: true,
        filterOptions: {
          fullWidth: true,
        },
        customBodyRender: (value) => {
          console.log(value);
          switch (value) {
            case "Inativo":
              return (
                <Chip
                  label="Inativo"
                  style={{
                    backgroundColor: "red",
                    color: "#FFFFFF",

                    width: "100%",
                    fontWeight: "600",
                  }}
                />
              );

            case "Ativo":
              return (
                <Chip
                  label="Ativo"
                  style={{
                    backgroundColor: "green",
                    color: "#FFFFFF",

                    width: "100%",
                    fontWeight: "600",
                  }}
                />
              );

            case "Cadastrado":
              return (
                <Chip
                  label="Cadastrado"
                  style={{
                    backgroundColor: "goldenrod",
                    color: "#FFFFFF",

                    width: "100%",
                    fontWeight: "600",
                  }}
                />
              );

            default:
              return (
                <Chip
                  label="Desconhecido"
                  style={{
                    backgroundColor: "gray",

                    width: "100%",
                    fontWeight: "600",
                  }}
                />
              );
          }
        },
      },
    },
  ];

  const handlePlan = (value) => {
    if (value?.id) {
      return value?.name;
    } else {
      switch (value) {
        case "Nenhum plano ativo":
          return "Gratuito";

        default:
          return value;
      }
    }
  };

  const handleStatus = (value) => {
    if (value?.active) {
      switch (value.status) {
        case "Dados Preenchidos":
          return "Inativo";

        case "Cliente Ativo":
          return "Ativo";
        default:
          return value;
      }
    } else {
      return "Cadastrado";
    }
  };

  const getClients = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/affiliates/partners");
      if (data) {
        //console.log(data);
        setClients(
          data.data.map((item) => {
            return {
              id: Number(item.id),
              clientName: item.attributes?.name || "Nome não informado",
              phoneNumber:
                item.attributes?.contactNumber || "Número não informado",
              clientEmail: item.attributes?.email || "Email não informado",
              tokenUsage: `${item.attributes?.montlyTokensConsumed} / ${
                item.attributes?.montlyTokensConsumed +
                item.attributes?.monthlyTokensLeft
              }`,
              subscriptionT: handlePlan(item?.attributes?.currentPlan),
              statusT: handleStatus(item?.attributes),

              ...item.attributes,
            };
          })
        );
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar a lista de clientes.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`partners/partner_clients/${id}`);
      setClients(clients.filter((client) => client.id !== id));
      toast.success("Cliente deletado com sucesso!");
      setClientId(null);
    } catch (error) {
      if (error?.response?.data?.errors[0]?.title)
        toast.error(
          `Erro ao deletar o cliente! Erro - ${error.response.data.errors[0].title}`
        );
      else toast.error("Erro ao deletar o cliente!");
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  return (
    <AuthorizedLayout>
      {loading ? (
        <Loader size={150} />
      ) : (
        <div style={{ margin: "24px" }}>
          <MUIDataTable
            title={"Clientes"}
            data={clients}
            columns={columns}
            options={{
              selectableRows: "none",
              tableBodyHeight: "65vh",
              sortFilterList: false,
              filter: true,
              textLabels: {
                body: {
                  noMatch: "Nenhum registro encontrado.",
                  toolTip: "Ordenar",
                  columnHeaderTooltip: (column) =>
                    `Ordenar por ${column.label}`,
                },
                pagination: {
                  next: "Página Seguinte",
                  previous: "Página Anterior",
                  rowsPerPage: "Linhas por página:",
                  displayRows: "de",
                },
                toolbar: {
                  search: "Pesquisar",
                  downloadCsv: "Fazer download de CSV",
                  print: "Imprimir",
                  viewColumns: "Ver Colunas",
                  filterTable: "Filtrar Tabela",
                },
                filter: {
                  all: "Todos",
                  title: "FILTROS",
                  reset: "Reiniciar",
                },
                viewColumns: {
                  title: "Mostrar Colunas",
                  titleAria: "Mostrar/Ocultar Colunas da Tabela",
                },
                selectedRows: {
                  text: "linha(s) selecionada(s)",
                  delete: "Apagar",
                  deleteAria: "Apagar linhas selecionadas",
                },
              },
            }}
          />
        </div>
      )}
      <ModalAlert
        handleClose={() => setModalAlert(false)}
        handleSubmit={() => handleDelete(clientId)}
        open={modalAlert}
      >
        <Typography variant={isMobileDevice ? "h7" : "h6"}>
          Tem certeza que deseja excluir esse cliente? Essa ação é irreversível
          e é destinada a testes.
        </Typography>
      </ModalAlert>
    </AuthorizedLayout>
  );
};
