import React, { useContext } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { AuthContext } from "../../providers/AuthProvider";
import { Button, Typography } from "@mui/material";

import image1 from "../../assets/fuundo_ondas (1).png";
import image2 from "../../assets/fuundo_ondas.png";
import logo from "../../assets/Frame 1.png";
import arrow from "../../assets/Vector.png";

import "./Login.css";

const Login = () => {
  const navigate = useNavigate();
  const { userType } = useParams();
  const { signIn, signInAdmin, signInSales } = useContext(AuthContext);
  const [loading, setLoading] = React.useState(false);

  const handleType = () => {
    switch (userType) {
      case "admin":
        return "Administrador";
      case "vendas":
        return "Afiliado";
      default:
        return "Parceiro";
    }
  };

  const type = handleType();

  const handleInv = () => {
    switch (userType) {
      case "admin":
        return ["Parceiro", "Afiliado"];
      case "vendas":
        return ["Parceiro", "Administrador"];
      default:
        return ["Afiliado", "Administrador"];
    }
  };

  const invType = handleInv();

  const form = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    validationSchema: yup.object({
      email: yup
        .string()
        .email("Formato de email inválido.")
        .required("Email é obrigatório!"),
      password: yup
        .string()
        .min(8, "Senha deve conter no mínimo 8 caracteres.")
        .required("Senha é obrigatória!"),
    }),

    onSubmit: (values) => handleContinue(values),
  });

  const handleContinue = async (values) => {
    const { email, password } = values;
    try {
      setLoading(true);
      if (type === "Administrador")
        await signInAdmin({ email: email, password: password });
      else if (type === "Afiliado") {
        await signInSales({ email: email, password: password });
      } else await signIn({ email: email, password: password });
      setLoading(false);
    } catch (error) {
      toast.error("Credenciais inválidas");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="containerLogin">
      <img src={image1} className="image1" alt="image1" />
      <img src={image2} className="image2" alt="image2" />

      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          display: "flex",  
        }}
      >
        {invType.map((inv, index) => (
          <button
            key={index}
            variant="text"
            type="submit"
            className="buttonTypeLogin"
            style={{
              marginTop: 15,
              marginRight: 15,
              display: inv === "Administrador" ? "none" : "flex",
            }}
            onClick={() => {
              if (inv === "Administrador") {
                return navigate("/admin");
              } else if (inv === "Afiliado") {
                return navigate("/vendas");
              } else return navigate("/");
            }}
          >
            <Typography
              color={"#102649"}
              className="textLogin"
              style={{ fontWeight: "600" }}
            >{`Entrar como ${inv}`}</Typography>
          </button>
        ))}
      </div>

      <div className="containerCenterLogin">
        <img
          src={logo}
          alt="logo"
          style={{ width: "300px" }}
          className="logoMain"
        />

        <div className="boxLogin">
          <form
            onSubmit={form.handleSubmit}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div className="labelInputLogin">E-mail</div>
            <input
              className="inputLogin"
              type="email"
              onChange={(e) => {
                form.setFieldValue("email", e.target.value);
              }}
              onBlur={() => {
                form.setFieldTouched("email", true);
              }}
            />

            {form.touched.email && form.errors.email && (
              <Typography
                style={{ fontSize: 14, alignSelf: "flex-end", marginTop: 5 }}
                color={"darkred"}
              >
                {form.errors.email}
              </Typography>
            )}

            <div className="labelInputLogin" style={{ marginTop: 15 }}>
              Senha
            </div>
            <input
              className="inputLogin"
              type="password"
              onChange={(e) => {
                form.setFieldValue("password", e.target.value);
              }}
              onBlur={() => {
                form.setFieldTouched("password", true);
              }}
            />

            {form.touched.password && form.errors.password && (
              <Typography
                style={{ fontSize: 14, alignSelf: "flex-end", marginTop: 5 }}
                color={"darkred"}
              >
                {form.errors.password}
              </Typography>
            )}

            {type === "Parceiro" && (
              <button
                type="button"
                onClick={() => {
                  navigate("/recuperacao");
                }}
                className="buttonParceiro"
                style={{
                  alignSelf: "flex-start",
                  marginTop: "5px",
                  marginLeft: "5px",
                }}
              >
                Esqueci minha senha
              </button>
            )}

            <button
              type="submit"
              className="buttonLogin"
              style={{ cursor: "pointer" }}
            >
              {loading ? (
                "Carregando..."
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "15px",
                  }}
                >
                  Entrar
                  <img src={arrow} alt="arrow" />
                </div>
              )}
            </button>
          </form>
        </div>

        {type === "Parceiro" && (
          <button
            onClick={() => {
              navigate("/cadastro");
            }}
            className="buttonParceiro"
          >
            Não é nosso parceiro?{" "}
            <strong style={{ textDecoration: "underline" }}>
              Cadastre-se agora!
            </strong>
          </button>
        )}

        {type === "Afiliado" && (
          <button
            onClick={() => {
              navigate("/vendas/cadastro");
            }}
            className="buttonParceiro"
          >
            Junte-se a nossa equipe de vendas!
          </button>
        )}
      </div>

      <footer className="footerLogin">
        sacgpt.com.br © 2024 - Todos os Direitos Reservados
      </footer>
    </div>
  );
};

export default Login;
